import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import {
  Box,
  Button,
  Flex,
  Image,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import EmailMe from "./components/EmailMe/EmailMe";
import CategoriesExploration from "./pages/CategoriesExploration";
import Landing from "./pages/Landing/Landing";
import CustomSpinner from "./UI/CustomSpinner";

// Assets
import notFound from "./assets/404.webp";
import { colours } from "./configs/styles";

// Authentication
import { Footer, Header } from "./components/Layout";
import Leaderboard from "./pages/LeaderBoard/Leaderboard";
import AuthModal from "./pages/Auth/AuthModal";
import About from "./pages/About";
import AddSite from "./pages/AddSite";
import Admin from "./pages/Admin";
import Refferal from "./pages/Refferal";
import useAppStore from "./stores/app";
import { useUserStore } from "./stores/user";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import supabase from "./stores/supaStore";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail";
import ResetPassword from "./pages/Auth/ResetPassword";
import EditSiteInfo from "./pages/Admin/EditSiteInfo";
import RegsiterManager from "./pages/Auth/Onboarding/RegsiterManager";
import { m } from "framer-motion";

function App() {
  // Set the history function & state in the useAppStore store
  const { setNavigate, pub, setIsMobile } = useAppStore((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const updateProfile = useUserStore((state) => state.updateProfile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  // Setup the navigation function
  useEffect(() => {
    setNavigate((e) => {
      console.log("e", e);
      navigate("/" + e);
    });
  }, []);

  const profile = useUserStore((state) => state.profile);
  useEffect(() => {
    const firstTimeUsername = localStorage.getItem("firstTimeUsername");

    const updateUser = async () => {
      await updateProfile(
        {
          username: firstTimeUsername,
        },
        () => {},
        () => {},
        () => {}
      );

      localStorage.removeItem("firstTimeUsername");
      console.log("App.jsx - [useEffect() depends - [profile]]");
    };

    const fireFathomCreatedUser = async () => {
      // Make a requst
    };

    firstTimeUsername && updateUser();
  }, [profile]);

  // ! The entire toast setup
  const u = useUserStore((state) => state);
  const setToast = useAppStore((state) => state.setToast);
  const toast = () => {};

  useEffect(() => {
    const asyncf = async () => {
      await u.initUser();
      if (u.isAdmin()) {
        setToast(toast);
        console.log("toast set, user is admin");
      }

      console.log("referral", localStorage.getItem("referral"));
      console.log("referralID", localStorage.getItem("referralID"));

      if (!window.location.pathname.includes("referral")) {
        if (localStorage.getItem("referral")) {
          console.log("referral");
          if (localStorage.getItem("referralID")) {
            console.log("referralID");
            const f2 = async () => {
              const profilename = await supabase
                .from("profiles")
                .select("*")
                .eq("username", localStorage.getItem("referralID"))
                .single();
              await supabase
                .from("profiles")
                .update({ coins: profilename.data.coins + 100 })
                .eq("username", localStorage.getItem("referralID"));
              localStorage.removeItem("referral");
              localStorage.removeItem("referralID");
            };
            f2();
          }
        }
      }
    };

    if (
      !window.location.href.includes("AllProfiles") ||
      !window.location.href.includes("AllSites") ||
      !navigator.userAgent.toLowerCase().includes("reactsnap")
    ) {
      try {
        asyncf();
      } catch {}
    }
  }, []);

  const setUsefulToast = useAppStore((state) => state.setUsefulToast);
  const usefulToast = () => {};

  useEffect(() => {
    setUsefulToast(usefulToast);
  }, []);

  const setExtraUsefultoast = useAppStore((state) => state.setExtraUsefulToast);
  const extraUsefulToast = useToast();

  useEffect(() => {
    setExtraUsefultoast(extraUsefulToast);
  }, []);
  // ! Toast setup end

  // ! Is Mobile setup ( It is only done here, so that it can be used in the entire app, without having to pass it down as props)
  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: false,
  });
  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile]);

  return (
    <div>
      {/* Page is not discover or homepage */}
      {!window.location.pathname.match(/\/discover\/\d+/) && (
        // Render the JSX component here
        <Box>
          <Header />
          <Box h="125px"></Box>
        </Box>
      )}
      <AuthModal />
      <Flex flexDir="column" minH="100vh">
        <Routes>
          <Route path="/" element={<Landing pub={pub} />} />
          {pub && !u.isLoading && (
            <>
              <Route path="/discover" element={<HomePage />} />
              <Route path="/discover/:id" element={<HomePage />} />
              <Route path="/profile/me" element={<ProfilePage />} />
              <Route path="/profile/edit" element={<ProfilePage />} />
              <Route path="/profile/*" element={<ProfilePage />} />
              <Route path="/categories" element={<CategoriesExploration />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/referral/:id" element={<Refferal />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              {u.isLoggedIn() && (
                <>
                  <Route path="/addsite" element={<AddSite />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                </>
              )}

              <Route path="/contact" element={<EmailMe />} />
              <Route path="/about" element={<About />} />
              {u.isAdmin() && (
                <Route path="/admin">
                  <Route index element={<Admin />} />
                  <Route path="edit/:id" element={<EditSiteInfo />} />
                </Route>
              )}
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              {/* <Route path="/AllProfiles" element={<AllProfiles />} /> */}
              {/* <Route path="/AllSites" element={<AllSites />} /> */}
            </>
          )}
          <Route path="/register/:page" element={<RegsiterManager />} />

          <Route
            path="*"
            element={
              <Flex
                align="center"
                justify="center"
                h="100vh"
                flexDir="column"
                gap="3"
              >
                {u.isLoading ? (
                  <CustomSpinner />
                ) : (
                  <>
                    <Image
                      src={notFound}
                      h="24vh"
                      aspectRatio="2 / 1"
                      alt="404 Not Found"
                    />{" "}
                    <Button bg={colours.primary} onClick={() => navigate("")}>
                      Go Home!
                    </Button>
                  </>
                )}
              </Flex>
            }
          />
        </Routes>
        {!window.location.pathname.match(/\/discover\/\d+/) && (
          <Flex w="100vw" bottom="0" mt="auto">
            <Footer />
          </Flex>
        )}
      </Flex>
    </div>
  );
}

export default App;
