import React from "react";
import { Text, Image, Center, Flex, Heading } from "@chakra-ui/react";
import logo from "../assets/logo.svg";
import useAppStore from "../stores/app";
import { colours } from "../configs/styles";

const Logo = ({ props }) => {
  const navigateTo = useAppStore((state) => state.navigateTo);

  return (
    <Flex
      flexDir="column"
      align="center"
      onClick={() => navigateTo("")}
      _hover={{ cursor: "pointer" }}
    >
      <Image src={logo} h="7" w="7" alt="Logo"/>
      <Heading fontSize="xs" color={colours.text.secondary}>
        CookieJar
      </Heading>
    </Flex>
  );
};

export default Logo;
