import { Flex, Image, Button, ButtonGroup } from "@chakra-ui/react";
import { colours } from "../../configs/styles";
import { CustomSpinner } from "../../UI";
import notFound from "../../assets/404.webp";

import { useAppStore, useSiteStore } from "../../stores";
import { copy as C } from "../../configs/copy";

/*
 * This is the main site loader component.
 * It is used to inject the site into the iframe.
 */
export default function WebsiteWindow({ site }) {
  const navigateTo = useAppStore((state) => state.navigateTo);
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].websiteWindow;
  const isLoading = useSiteStore((state) => state.isLoading);

  return (
    <>
      <Flex>
        {!site ? (
          <Flex
            align="center"
            flexDir="column"
            justify="center"
            height="100vh"
            w="100vw"
            zIndex={"1"}
          >
            <Image src={notFound} h="20vh" width="fit-content" />
            <ButtonGroup>
              <Button
                bg="red.400"
                onClick={() => {
                  localStorage.setItem("category", "[]");
                  window.location.reload();
                }}
              >
                {copy.useAllCategorires}
              </Button>
              <Button
                bg={colours.primary}
                onClick={() => {
                  navigateTo("categories");
                }}
              >
                {copy.selectCategories}
              </Button>
            </ButtonGroup>
          </Flex>
        ) : isLoading ? (
          <Flex align="center" justify="center" w="100%" h="100vh">
            <CustomSpinner />
          </Flex>
        ) : (
          <iframe
            src={site || "https://kensavage.com"}
            style={{ width: "100vw", height: "100vh", zIndex: "1" }}
          ></iframe>
        )}
      </Flex>
    </>
  );
}
