import React, { useEffect, useState } from "react";
import MainPage from "../../components/MainPage";
import AddSiteCard from "../../components/AddSite/AddSite";
import useSiteStore from "../../stores/sites";
import { useParams } from "react-router-dom";

const EditSiteInfo = () => {
  const { id } = useParams();
  const [input, setInput] = useState({});
  const getParamSite = useSiteStore((state) => state.getParamSite);

  useEffect(() => {
    const asyncf = async () => {
      const site = await getParamSite(id);
      console.log("[SITE] - ", site);
      setInput(site);
    };
    asyncf();
  }, [id]);
  
  return (
    <MainPage title={"Edit Site x"} desktopWidth={"60%"} top={"-40"} labelText={'Admins can modify the properties of a website here.'}>
      <AddSiteCard input={input} />
    </MainPage>
  );
};

export default EditSiteInfo;
