import { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Text,
  SimpleGrid,
  Button,
  Badge,
  Box,
} from "@chakra-ui/react";
import { colours } from "../../configs/styles";
import { CustomSpinner } from "../../UI";
import { MainPage } from "../../components";

import { useAppStore, useCategoriesStore } from "../../stores";
import GeneralLabel from "../../UI/GeneralLabel";

export const CategoryItem = ({
  category,
  onClick,
  toggledCategories = [],
  siteStat,
  isTop,
}) => {
  const [siteCount, setSiteCount] = useState(category.count);

  return (
    <Button
      p="2"
      cursor="pointer"
      borderRadius="md"
      bg={
        toggledCategories.includes(category.id)
          ? colours.action.primary
          : colours.primary
      }
      _hover={{
        bg: toggledCategories.includes(category.id)
          ? "orange.400"
          : "orange.400",
        color: "white",
      }}
      fontSize="16px"
      onClick={() => onClick(category)}
      w="100%"
      color="white"
    >
      <Text
        fontWeight="bold"
        color="white"
        gap="4"
        mr="auto"
        w="100%"
        display="flex"
        align="center"
        justify="space-between"
      >
        <Box>
          {category.name} {siteStat && `${siteCount}`}{" "}
          {isTop ? (
            <Badge variant="solid" colorScheme="orange" ml="4" p="1">
              NEW
            </Badge>
          ) : null}
        </Box>
        <Box ml="auto">
          <GeneralLabel labelText={category?.tooltip || ""} informative />
        </Box>
      </Text>
    </Button>
  );
};

export default function CategoriesExploration() {
  const {
    all: unCleanedCategories,
    getAllUniqueMain,
    getAllUsed,
  } = useCategoriesStore((state) => state);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { navigateTo, isMobile } = useAppStore((state) => state);

  const clickDirect = (name) => {
    navigateTo("discover/" + name.name);
  };

  useEffect(() => {
    const asyncf = async () => {
      setIsLoading(true);

      const cats = await getAllUniqueMain(setIsLoading);
      const used = await getAllUsed(setIsLoading);
      console.log(cats, "ats");
      const filteredCats = cats.filter((category) =>
        used.includes(category.id.toString())
      );

      // sort the categories by their name ( Which is called category.name)
      const sortedCategories = filteredCats.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      setCategories(sortedCategories);
      setIsLoading(false);
    };
    asyncf();
  }, []);

  return (
    <Flex flexDir="column" align="center" gap={5} w="100vw" minH="100vh">
      <MainPage
        top="-40"
        title="Categories"
        justify="left"
        desktopWidth="70%"
        labelText={
          "You can choose a main category to browse. Click the one that most interests you!"
        }
      >
        <Flex flexDir="column" align="center" justify="center" gap="3" p="4">
          <Heading fontSize="2xl" mb="5" mr="auto">
            Choose a category to browse
          </Heading>
          {isLoading ? (
            <CustomSpinner />
          ) : (
            <SimpleGrid
              gap="4"
              minChildWidth="250px"
              w="90%"
              p="4"
              justifyContent={"center"}
            >
              {categories.map((category, index) => (
                <CategoryItem
                  category={category}
                  onClick={clickDirect}
                  // siteStat
                  isTop={index < 3}
                  key={category.id}
                />
              ))}
            </SimpleGrid>
          )}
        </Flex>
      </MainPage>
    </Flex>
  );
}
