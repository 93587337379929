import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import AuthHandler from "./../index";
import ConfirmEmail from "../../ConfirmEmail/ConfirmEmail";
import RegistrationProfileModify from "../../ProfilePage/RegistrationProfileModify";
import { MainPage } from "../../../components";
import RegsitrationCategoriesSelection from "../../CategoriesExploration/RegsitrationCategoriesSelection";
import { useUserStore } from "../../../stores";
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Flex,
  Card,
  Stack,
  CardBody,
} from "@chakra-ui/react";
import { colours } from "../../../configs/styles";

const steps = [
  { title: "First", description: "Register an Account" },
  { title: "Second", description: "Confirm Email" },
  { title: "Third", description: "choose interests" },
];

const RegsiterManager = () => {
  // THere is a :page param for this route
  // Page 1 = registration process
  // Page 2 = Confirm email
  // Page 3 = Profile creation
  // Page 4 = Categories selection
  // We then redirect them to the discover page

  const { page } = useParams();

  const { activeStep, setActiveStep } = useSteps({
    index: parseInt(page),
    count: steps.length,
  });

  useEffect(() => {
    try {
      setActiveStep(parseInt(page));
    } catch {}
  }, [page]);

  useEffect(() => {
    // This is how we will call fathom to track the event that an account was created
    // This is the id: WQDQ8B2H
    fathom = window.fathom || {};
    try {
      fathom.trackGoal("WQDQ8B2H", 0);
    } catch {
      console.log("fathom not loaded");
    }
  }, [page]);

  return (
    <>
      <Flex justifyContent="center">
        <Card bg={colours}>
          <CardBody p="2">
            <Stepper index={activeStep} colorScheme={"orange"}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    alignItems="center"
                  >
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon color={"white"} />}
                        incomplete={<StepNumber color={colours.primary} />}
                        active={<StepNumber color={colours.primary} />}
                      />
                    </StepIndicator>

                    <Box
                      flexShrink="0"
                      display="flex"
                      alignItems={"center"}
                      flexDir="column"
                      textAlign={"center"}
                    >
                      <StepDescription
                        as="p"
                        maxWidth="10ch"
                        wordWrap="break-word"
                      >
                        {step.description}
                      </StepDescription>
                    </Box>
                  </Stack>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </CardBody>
        </Card>
      </Flex>
      {page < 3 && (
        <MainPage
          title={page == 1 ? "Register" : ""}
          labelText={
            page == 1
              ? "Enter your desired Email & password to create an account on CookieJar!"
              : page == 2
              ? "Please Open your inbox to confirm your email!"
              : page == 4 &&
                "Select the category that interests you. It can be multiple as well!"
          }
          marginTop="-175px"
        >
          {page == 1 && <AuthHandler />}
          {page == 2 && <ConfirmEmail />}
        </MainPage>
      )}
      {page == 3 && <RegsitrationCategoriesSelection />}
    </>
  );
};

export default RegsiterManager;
