import { Tooltip } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import { useAppStore } from "../stores";

const GeneralLabel = ({ labelText }) => {
  const isMobile = useAppStore((state) => state.isMobile);

  return (
    <>
      {!isMobile ? (
        <Tooltip label={labelText}>
          <QuestionIcon fontSize="md" ml="1" mt="-1" />
        </Tooltip>
      ) : (
        <></>
      )}{" "}
    </>
  );
};

export default GeneralLabel;
