import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { extendTheme } from "@chakra-ui/react";
import { HelmetProvider } from "react-helmet-async";


const customTheme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        color: "black",
      },
    },
    Link: {
      baseStyle: {
        textDecoration: "none", // Remove underline
        color: "black", // Set the color to black
      },
    },
  },
  styles: {
    global: {
      a: {
        textDecoration: "none",
        color: "black",
      },
    },
  },
});

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ChakraProvider theme={customTheme}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);
