import { useUserStore, useAppStore } from "./../../stores";

import {
  Box,
  Container,
  Flex,
  Grid,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import logo from "../../assets/logo.svg";
import { Link as RouterLink } from "react-router-dom";

const Logo = (props) => {
  return <Image src={logo} alt="Logo" w="40px" h="40px" {...props} />;
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

/*
 * This is the footer component that is used on the website
 * It contains links to the main pages of the website
 * And social media links
 */
export default function LargeWithLogoLeft() {
  const {
    authModal: { onOpen: authModalOnOpen },
    navigateTo,
  } = useAppStore((state) => state);

  const isLoggedIn = useUserStore((state) => state.isLoggedIn);

  return (
    <Box
      bg={useColorModeValue("gray.200", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      w="100%"
      display="flex"
      justifyContent="center"
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          spacing={8}
          flexWrap="wrap"
          justifyContent="center"
        >
          <Stack spacing={6} />
          <Stack spacing={6}>
            <Flex gap="4" flexDir="column">
              <Flex gap="4" align="center">
                <Logo color={useColorModeValue("gray.700", "white")} />
                <Text fontSize={"sm"}>
                  © {new Date().getFullYear()} Cookiejar.co
                </Text>
              </Flex>
              <>
                <a
                  href="https://www.producthunt.com/posts/cookiejar?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-cookiejar"
                  target="_blank"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=419421&theme=neutral&period=daily"
                    alt="CookieJar - Do&#0032;you&#0032;miss&#0032;StumbleUpon&#0044;&#0032;find&#0032;the&#0032;coolest&#0032;shiz&#0032;online | Product Hunt"
                    width="100%"
                    height="54"
                  />
                </a>
              </>
            </Flex>
          </Stack>
          <Grid
            templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
            templateRows={{ base: "repeat(2, 1fr)", md: "1fr" }}
            gap="10"
            justifyItems="center"
            justifyContent={{ base: "center", md: "initial" }}
            w="100%"
          >
            <Stack align={"flex-start"}>
              <ListHeader>Product</ListHeader>
              <Link
                as={RouterLink}
                to={isLoggedIn() ? "/addSite" : ""}
                onClick={() => {
                  isLoggedIn() ? navigateTo("addSite") : authModalOnOpen();
                }}
              >
                Add Site
              </Link>
              <Link as={RouterLink} to={"/discover"}>
                Discover
              </Link>
              <Link as={RouterLink} to={"/categories"}>
                Categories
              </Link>
            </Stack>
            <Stack align={"flex-start"}>
              <ListHeader>Company</ListHeader>
              <Link as={RouterLink} to={"/about"}>
                About
              </Link>
              <Link as={RouterLink} to={"/contact"}>
                Contact
              </Link>
            </Stack>
            <Stack align={"flex-start"}>
              <ListHeader>Support</ListHeader>

              <Link as={RouterLink} to={"/privacy-policy"}>
                Privacy
              </Link>
              <Link as={RouterLink} to={"/contact?reason=sponsorship"}>
                Sponsorship
              </Link>
            </Stack>
            <Stack align={"flex-start"}>
              <ListHeader>Follow</ListHeader>
              <Link href={"https://twitter.com/cookiejardotco"} isExternal>
                Twitter
              </Link>
            </Stack>
          </Grid>
          <Stack align={"flex-start"} />
        </SimpleGrid>
      </Container>
    </Box>
  );
}
