// categiesStore.js
import { create } from "zustand";
import { supabase, useAppStore } from "./index";

const useCategoriesStore = create((set, get) => ({
  all: [],
  used: [],
  cleaned: [],

  // Functions to fetch all categories
  fetchAll: async (setIsLoading) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    try {
      setIsLoading(true);
      const { data, error } = await supabase.from("categories").select("*");

      if (error) {
        throw new Error("Error fetching categories");
      }

      set({ all: [...data] });
      toast({
        title: "Categories Fetched!",
        description: "All categories have been fetched!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      set({ all: [] });
      usefulToast({
        title: "Categories Not Fetched!",
        description: "All categories have Not been fetched!",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  },

  getAllUniqueMain: async () => {
    try {
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .is("parent_id", null);

      // for (const category of data) {
      //   const { data: data1, error: error1 } = await supabase
      //     .from("websites")
      //     .select("category")
      //     .eq("status", "approved")
      //     .eq("category", category.id);

      //   category.count = data1.length;
      // }

      if (error) return [];
      return data;
    } catch {
      return [];
    }
  },

  getAllSubFromMainID: async (mainCategoryID, isUsed = false) => {
    try {
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .eq("parent_id", mainCategoryID);
      console.log(isUsed);
      if (isUsed) {
        const { data: data1, error: error1 } = await supabase
          .from("websites")
          .select("subcategory")
          .eq("status", "approved");
        console.log(data1);
        if (error1) return ["aaa"];
        console.log(
          "data",
          data.filter((e) =>
            data1.map((e1) => Number(e1.subcategory)).includes(Number(e.id))
          )
        );
        return data.filter((e) =>
          data1.map((e1) => Number(e1.subcategory)).includes(Number(e.id))
        );
      }
      if (error) return [];
      return data;
    } catch {
      return [];
    }
  },

  getAllUsed: async (setIsLoading) => {
    // Get all the used categories

    let usedCategories = [];
    const toast = useAppStore.getState().usefulToast;
    setIsLoading(true);
    try {
      const { data, error } = await supabase.from("distinct_category").select();
      const { data: data1, error: error1 } = await supabase
        .from("distinct_subcategory")
        .select();
      const { data: data2, error: error2 } = await supabase
        .from("distinct_subsubcategory")
        .select();

      if (error || error1 || error2) {
        throw new Error(
          "Some Error occurred whilst fetching unique categories"
        );
      }

      usedCategories = [
        ...data.map((e) => e.category),
        ...data1.map((e) => e.subcategory),
        ...data2.map((e) => e.sub_subcategory),
      ];
      console.log("Used Categories", usedCategories);
    } catch (e) {
      usefulToast({
        title: e || "Some error occurred whilst fetching the unique categories",
        status: "error",
        isClosable: true,
        duration: 9000,
      });
      console.error(e);
    } finally {
      setIsLoading(false);
      return usedCategories;
    }
  },

  // Functions to fetch top 5 sites of category
  gettop5Sites: async (id) => {
    try {
      // Sites status should only be approved & not anything else
      const { data, error } = await supabase
        .from("websites")
        .select("*")
        .eq("category", id)
        .eq("status", "approved")
        .order("views", { ascending: false })
        .limit(4);

      if (error) {
        throw new Error("Error fetching top 5 sites");
      }
      return data;
    } catch {}
  },

  // Function to identify category by name
  convertNameToID: async (name) => {
    try {
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .eq("name", name);

      if (error) {
        throw new Error("Error fetching category");
      }

      return data[0].id;
    } catch {
      return null;
    }
  },

  // Function to get the count of sites in that main category
  getSiteCount: async (id) => {
    try {
      const { data, error } = await supabase
        .from("websites")
        .select("*")
        // Sites should only select approved sites
        .eq("category", id)
        .eq("status", "approved");

      if (error) {
        throw new Error("Error fetching category");
      }

      return data.length;
    } catch {
      return null;
    }
  },

  // Function to get all the categories that a site has
  getSiteCategories: async (id) => {
    try {
      console.log("da id", id);
      const { data, error } = await supabase
        .from("websites")
        .select("category")
        .eq("id", id);

      const { data: subCategoryData, error: subCategoryError } = await supabase
        .from("websites")
        .select("subcategory")
        .eq("id", id);

      console.log(
        data
          .map((e) => e.category)
          .concat(subCategoryData.map((e) => e.subcategory))
          .filter((e) => e !== null)
      );
      const { data: data1, error: error1 } = await supabase
        .from("categories")
        .select("name")
        .in(
          "id",
          data
            .map((e) => e.category)
            .concat(subCategoryData.map((e) => e.subcategory))
            .filter((e) => e !== null)
        );

      if (error) {
        throw new Error("Error fetching category");
      }

      return data1;
    } catch {
      return null;
    }
  },
}));

export default useCategoriesStore;
