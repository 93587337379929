import { create } from "zustand";

import {supabase, useAppStore} from './index'

export const useLeaderboardStore = create((set, get) => ({
  sites: [],
  isLoading: false,
  userLikes: [],
  userCoins: [],

  getSites: async () => {
    const toast = useAppStore.getState().usefulToast;

    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from("websites")
        .select("*")
        .order("views", { ascending: false })
        .eq("status", "approved")
        .limit(20);

      if (error) {
        throw new Error("Error getting top Sites");
      }

      set({ sites: data });
    } catch (e) {
      toast({
        title: "Error",
        description: e.message + "error lb30" || "error getting top sites",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },

  getUserLikes: async () => {
    const toast = useAppStore.getState().usefulToast;

    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .order("like_count", { ascending: false })
        .limit(10);

      if (error) {
        throw new Error("Error getting top Liked Users");
      }

      set({ userLikes: data });
    } catch (e) {
      toast({
        title: "Error",
        description: e.message + "err lb59" || "error getting top liked Users",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },

  getUserCoins: async () => {
    const toast = useAppStore.getState().usefulToast;

    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .order("coins", { ascending: false })
        .limit(10);

      if (error) {
        throw new Error("Error getting top Cookied Users");
      }

      set({ userCoins: data });
    } catch (e) {
      toast({
        title: "Error",
        description:
          e.message + "err lb88" || "error getting top Cookied Users",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },
}));
