import { useState, useEffect } from "react";
import { Input, Button, Box, Text, Flex, Heading } from "@chakra-ui/react";

import { supabase } from "../../stores";

import { CustomSpinner } from "../../UI"; // Import the CustomSpinner component
import { colours } from "../../configs/styles";

import { useUserStore, useAppStore } from "../../stores";
import { useLocation } from "react-router-dom";

function AuthComponent() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const { auth_view, setAuthView, isUsernameUnique } = useUserStore(
    (state) => state
  );
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [mode, setTheMode] = useState(
    auth_view == "sign_in" ? "login" : "register"
  ); // 'login', 'register', 'reset', 'confirm', 'reset-confirm'
  const [isLoading, setIsLoading] = useState(false); // Add a loading state
  const [error, setError] = useState("");
  const { navigateTo } = useAppStore((state) => state);
  const { authModal } = useAppStore((state) => state);

  const setMode = (mode) => {
    setTheMode(mode);
    if (!["reset", "confirm"].includes(mode)) {
      setAuthView(
        mode == "login"
          ? "sign_in"
          : mode == "register"
          ? "sign_up"
          : "reset-password  "
      );
    }
  };

  const handleLogin = async () => {
    setIsLoading(true); // Set loading to true when making the request
    try {
      const { user, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        // Handle login error
        console.error("Login error:", error);
        setError("Invalid Login Credentials");
      } else {
        // Handle successful login
        console.log("Logged in as:", user);
        window.location.reload();
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setIsLoading(false); // Set loading to false when the request is done
    }
  };

  const handleRegistration = async () => {
    setIsLoading(true); // Set loading to true when making the request

    if (username.length < 3) {
      setError("Username must be at least 3 characters long");
      setIsLoading(false); // Set loading to true when making the request
      return;
    }

    if (!(await isUsernameUnique(username))) {
      setError("Username is already taken");
      setIsLoading(false); // Set loading to true when making the request
      return;
    }

    // Set the username so that we can use it later on.
    localStorage.setItem("firstTimeUsername", username);

    try {
      const { user, error } = await supabase.auth.signUp({ email, password });

      if (error) {
        // Handle registration error
        console.error("Registration error:", error);
        setError(error.message || "Error Creating Account");
      } else {
        setMode("confirm"); // Switch to confirmation mode
      }
    } catch (error) {
      console.error("Registration error:", error);
      setError(error.message || "Error Creating Account");
    } finally {
      setIsLoading(false); // Set loading to false when the request is done
    }
  };

  const handlePasswordReset = async () => {
    setIsLoading(true); // Set loading to true when making the request
    try {
      await supabase.auth.resetPasswordForEmail(email);
      setMode("reset-confirm"); // Switch to password reset confirmation mode
    } catch (error) {
      console.error("Password reset error:", error);
    } finally {
      setIsLoading(false); // Set loading to false when the request is done
    }
  };

  useEffect(() => {
    if (mode == "confirm") {
      navigateTo("register/2");
    }
  }, [mode]);

  useEffect(() => {
    if (location.pathname.includes("register")) {
      setMode("register");
    }
  }, [location]);

  return (
    <Box m="auto">
      {mode === "login" && (
        <Flex flexDir="column" p="3" gap="2">
          <Text w="100%">
            You'll earn cookie points and liked pages are saved in your profile.
          </Text>

          <Box>
            <Input
              bg={"white"}
              border="1px solid rgba(0,0,0,0.3)"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box>
            <Input
              bg={"white"}
              border="1px solid rgba(0,0,0,0.3)"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              mb="2"
            />
          </Box>
          <Flex align="center" justify="center" flexDir="column" gap="2">
            {isLoading ? (
              <CustomSpinner /> // Show spinner when loading
            ) : (
              <Button
                bg={colours.primary}
                color={"white"}
                onClick={handleLogin}
                mr="2"
              >
                Login
              </Button>
            )}
            <Flex justify="space-between" cursor="pointer  ">
              <Text onClick={() => setMode("register")} mr="10">
                Need an account?
              </Text>
              <Text onClick={() => setMode("reset")}>Forgot Password</Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      {mode === "register" && (
        <Flex flexDir="column" gap="3" p="3">
          {window.location.pathname.includes("register") ? (
            <>
              <Text w="100%">
                You can save websites and earn cookie points.
              </Text>
              <Box>
                <Input
                  bg={"white"}
                  border="1px solid rgba(0,0,0,0.3)"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Box>
              <Box>
                <Input
                  bg={"white"}
                  border="1px solid rgba(0,0,0,0.3)"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Box>
              <Box>
                <Input
                  bg={"white"}
                  border="1px solid rgba(0,0,0,0.3)"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Flex align="center" justify="center" flexDir="column" gap="2">
                {isLoading ? (
                  <CustomSpinner /> // Show spinner when loading
                ) : (
                  <Button
                    bg={colours.primary}
                    color={"white"}
                    onClick={handleRegistration}
                    mr="2"
                  >
                    Register
                  </Button>
                )}
                <Flex justify="space-between" cursor="pointer">
                  <Text onClick={() => setMode("login")} mr="10">
                    Already have an account?
                  </Text>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex flexDir={"column"} gap="4">
              <Text>
                Would you like to save websites and earn cookie points? <br />
                You can create an account by clicking the button below.
              </Text>

              <Button
                bg={colours.primary}
                color={"white"}
                onClick={() => {
                  navigateTo("register/1");
                  authModal.onClose();
                }}
                mr="2"
              >
                Create account
              </Button>
            </Flex>
          )}
        </Flex>
      )}

      {mode === "reset" && (
        <Box p="5">
          <Input
            bg={"white"}
            border="1px solid rgba(0,0,0,0.3)"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mb="2"
          />
          {isLoading ? (
            <CustomSpinner /> // Show spinner when loading
          ) : (
            <Button bg={colours.primary} onClick={handlePasswordReset} mb="2">
              Reset Password
            </Button>
          )}
        </Box>
      )}

      {mode === "reset-confirm" && (
        <Box>
          <Text>Please check your email to reset your password.</Text>
        </Box>
      )}
      <Box mb="2" />
      <Flex textAlign="center" align="center" justify="center">
        <Text color="red.500">{error}</Text>
      </Flex>
    </Box>
  );
}

export default AuthComponent;
