import {
  Flex,
  Heading,
  Image,
  Card,
  CardBody,
  CardHeader,
  Box,
} from "@chakra-ui/react";

import { colours } from "../../configs/styles";

import { useAppStore } from "../../stores";
import GeneralLabel from "../../UI/GeneralLabel";


/*
* This is the MainPage Component.
* It is the most common component used in the app.
* It has a central image and a card, with children & a helper label/tooltip
*/
export default function MainPage({
  title,
  desktopWidth,
  children,
  labelText,
  marginTop = "-150px",
}) {
  const { isMobile } = useAppStore((state) => state);

  return (
    <Flex
      w="100vw"
      h="fit-content"
      justify="center"
      align="center"
      mt={marginTop}
      flexDir="column"
    >
      <Image
        src={"https://source.unsplash.com/random/?city"}
        w="100vw"
        mt="-150px"
        h="80vh"
        objectFit={"cover"}
        zIndex={"-1"}
      />
      <Flex w="100vw" justify="center" h="min-content">
        <Card
          position={"relative"}
          border={"1px solid black"}
          top={"-40"}
          display={"flex"}
          align="center"
          bg={colours.secondary}
          w={isMobile ? "100vw" : desktopWidth}
          mb="2"
        >
          <CardHeader w="100%">
            <Flex align="center" justify={"left"} w="100%">
              <Box
                borderTop={"1px solid black"}
                pos="absolute"
                top="-5"
                borderX={"1px solid black"}
                bg={colours.secondary}
                px="4"
                h="20px"
                rounded={"md"}
                roundedBottom={"none"}
              >
                <Heading as="h1" size="lg" lineHeight={"4"} mt="3">
                  {title}
                  <GeneralLabel labelText={labelText} />
                </Heading>
              </Box>
            </Flex>
          </CardHeader>
          <CardBody w="100%" mt="1">
            {children}
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}
