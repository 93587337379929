import { create } from "zustand";

import {
  uploadImageToStorage,
  getSignedImageUrl,
} from "../functions/supabaseUtils";

import {
  supabase,
  useAppStore,
  useSiteStore,
  useCategoriesStore,
} from "./index";

export const useUserStore = create((set, get) => ({
  user: {},
  setUser: (user) => set({ user }),
  isLoading: true,
  setAuthView: (auth_view) => set({ auth_view }),
  auth_view: "sign_in",
  isReferral: false,
  setReferral: (isReferral) => {
    localStorage.setItem("referral", isReferral);
    set({ isReferral });
  },
  referralID: false,
  setReferralID: (referralID) => {
    localStorage.setItem("referralID", referralID);
    set({ referralID });
  },

  getUser: async () => {
    try {
      let userData = await supabase.auth?.getSession();

      if (!userData?.data?.session) {
        userData = await supabase.auth.refreshSession();
      }

      set({ user: userData.data }); // Fix: Access userData.data
      console.log("getUser:", userData.data); // Logging the actual user data
      return userData;
    } catch (error) {
      console.error("getUser Error:", error);
      throw error;
    }
  },

  setProfile: (profile) => set({ profile }),

  getProfile: async (userData) => {
    if (!get().isLoggedIn()) return;

    set({ profileLoading: true });
    const userID = userData.session.user.id;

    try {
      let { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", userID)
        .single();

      if (error) {
        console.error("getProfile Error:", error);
      }

      if (!data) {
        get().createProfile(userData);
      }

      if (!data["username"]) {
        await supabase
          .from("profiles")
          .update({ username: data["id"] })
          .eq("id", data["id"])
          .select();
        data["username"] = data["id"];
      }

      console.log("PROFILE", data);

      set({ profileLoading: false });
      set({ profile: data });
      console.log("getProfile:", data);
    } catch (error) {
      console.error("getProfile Error:", error);
      set({ profileLoading: false });
      throw error;
    }
  },

  getCategories: async (profileData) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    if (!profileData || !profileData.categories) {
      set({ categoryIDs: [] });
      return;
    }

    let categoriesData = profileData.categories;
    console.log("CATEGORY DATA FOR PROFILE: ", categoriesData);

    try {
      const { data: categories, error } = await supabase
        .from("categories")
        .select("name")
        .in("id", categoriesData);

      console.log("FINAL CATEGORIES TO DISPLAY: ", categories);
      console.log(error);
      if (error) {
        throw new Error("Error getting categories");
      }

      set({ categories });
      set({ categoryIDs: categoriesData });
      console.log("getCategories:", categories);
    } catch (error) {
      usefulToast({
        title: error?.message || "Error Getting Categories",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  },

  getLikedSites: async (userID) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    if (!get().isLoggedIn()) return;

    try {
      const { data: likedSites, error } = await supabase
        .from("liked_sites")
        .select("*")
        .eq("user_id", userID)
        .order("created_at", { ascending: false })
        .limit(50);

      if (error || likedSites.length == 0) {
        throw new Error("Coudln't get User Liked Sites");
      }

      const { data: likedSitesValues, error: likedSitesValuesError } =
        await supabase
          .from("websites")
          .select("*")
          .in(
            "id",
            likedSites.map((item) => item.site).filter((e) => e !== null)
          )
          .eq("status", "approved");

      if (likedSitesValuesError) {
        throw new Error(
          likedSitesValuesError || "Couldn't fetch site values for user"
        );
      }

      set({ likedSites: likedSitesValues });
      console.log("getLikedSites:", likedSites);
    } catch (error) {
      // usefulToast({
      //   title: "Error",
      //   description: JSON.stringify(error + 'error is here') || "Error getting liked sites",
      //   status: "error",
      //   duration: 9000,
      //   isClosable: true,
      // })
    }
  },

  initUser: async () => {
    set({ isLoading: true });

    try {
      const store = get();
      console.log(store);
      const siteStore = useSiteStore.getState();
      const userDataResponse = await store.getUser();
      const userData = userDataResponse.data;
      console.log("initUser user:", userData);

      await store.getProfile(userData);
      console.log("initUser profile:", get().profile);

      // getProfile & getLikedSites in one go
      const { data, error } = await supabase
        .from("profiles")
        .select("*, liked_sites_2(*), websites(*)")
        .eq("id", userData?.session?.user?.id)
        .limit(50, { foreignTable: "liked_sites_2" })
        .order("created_at", {
          ascending: false,
          foreignTable: "liked_sites_2",
        })
        .limit(50, { foreignTable: "websites" })
        .single();

      // Example response this will return
      // {
      //   "id": "19fb6674-60af-4dd8-b08b-5e3e169b9f8a",
      //   "username": "test",
      //   "email": "test@test",
      //   "categories": [
      //     1,
      //     2,
      //     3
      //   ],
      //   "coins": 0,
      //   "liked_sites_2": [
      //     {
      //       "id": 1,
      //       "site": 1,
      //       "user_id": "19fb6674-60af-4dd8-b08b-5e3e169b9f8a",
      //       "created_at": "2021-10-18T22:52:51.000Z",
      //     },
      //     {
      //       "id": 2,
      //       "site": 2,
      //       "user_id": "19fb6674-60af-4dd8-b08b-5e3e169b9f8a",
      //       "created_at": "2021-10-18T22:52:51.000Z",
      //     },
      //     ]
      //   }

      await store.getCategories(get().profile);
      console.log("initUser categories:", get().categories);

      await store.getLikedSites(userData?.session?.user?.id);
      console.log("initUser likedSites:", get().likedSites);

      await siteStore.fetchCategorySites(get().profile?.categories || null);
      console.log("initUserCategorySites:", useSiteStore.getState().sites);
      console.log("STATE", get());
    } catch (error) {
      console.error("initUser Error:", error);
      throw error;
    } finally {
      set({ isLoading: false });
      // set({ isLoading: false });
      return get(); // Return the populated user state
    }
    set({ isLoading: false });
  },

  isLoggedIn: () => {
    const { user } = get();
    console.log("[isLoggedIn] - user data is: ", user);
    console.log("Checking Login with user:", user);
    return user?.session ? true : false;
  },

  isAdmin: () => {
    const { profile } = get();
    return ["admin", "owner", "developer", "founder"].includes(
      profile?.user_type
    );
  },

  getID: () => {
    const { user } = get();
    return user?.session?.user?.id;
  },

  logout: async () => {
    try {
      await supabase.auth.signOut();
      set({ user: {} });
      console.log("Successfully Logged out: ", get().user);
      window.location.reload();
    } catch (error) {
      console.error("logout Error:", error);
      throw error;
    }
  },

  login: async () => {
    const state = get();
    set({ auth_view: "sign_in" });
    useAppStore.getState().authModal.onOpen();
  },

  register: async () => {
    const state = get();
    set({ auth_view: "sign_up" });
    useAppStore.getState().authModal.onOpen();
  },

  getOwnerEmail: async (siteId) => {
    if (!get().isAdmin) return;
    const toast = useAppStore.getState().toast;

    set({ isLoading: true });
    let email = null;
    try {
      const { data, error } = await supabase
        .from("websites")
        .select("user_id")
        .eq("id", siteId)
        .eq("status", "approved");

      if (error) {
        console.log(
          "[C1] - Error code for fetching email: ",
          JSON.stringify(error)
        );
        throw new Error("Could'nt Get User Email!");
      }

      let { data: email, error: emailError } = await supabase
        .from("profiles")
        .select("email")
        .eq("id", data[0].user_id);

      console.log(email);

      if (emailError) {
        console.log(
          "[C2] - Error code for fetching email: ",
          JSON.stringify(emailError)
        );
        throw new Error("Couldn'nt Get User Email!");
      }

      toast({
        title: "Owner Email",
        description: email[0].email,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      console.log("getOwnerEmail:", email[0].email);
      email = email[0].email;
    } catch (e) {
      toast({
        title: e?.message || "Error Getting Owner Email",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
      return email;
    }
  },

  createProfile: async (userData) => {
    if (!get().isLoggedIn()) {
      console.log("user not logged in");
      return;
    }

    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    set({ isLoading: true });
    try {
      console.log(
        "TRYING TO CREATE PROFILE WITH USER EMAIL & STATE OF: ",
        get().user,
        get().user?.session?.user?.email
      );
      const { data, error } = await supabase.from("profiles").insert({
        id: get().getID(),
        username: userData.session.user.email,
        email: userData.session.user.email,
        categories: [],
        coins: 0,
      });

      if (error) {
        throw new Error("Couldn't Create Profile!");
      }

      set({ profile: data[0] });
      usefulToast({
        title: "Profile Created",
        description: "Your profile has been created successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      usefulToast({
        title: e?.message || "Error Creating Profile",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },

  updateCategories: async (categories, setIsLoading) => {
    const isLoggedIn = get().isLoggedIn;
    const navigateTo = useAppStore.getState().navigateTo;
    const getID = get().getID;
    const fetchCategorySites = useSiteStore.getState().fetchCategorySites;
    const sites = useSiteStore.getState().sites;
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;
    const profile = get().profile || {};

    try {
      setIsLoading(true);
      if (!isLoggedIn()) {
        set({ categories: categories });
        set({
          profile: { ...profile, categories: categories },
          categoryIDs: categories,
        });
        await fetchCategorySites(categories);
        // usefulToast({
        //   title: "Categories Updated",
        //   description: "Your categories have been updated successfully!",
        //   status: "success",
        //   duration: 9000,
        //   isClosable: true,
        // });
        navigateTo("discover/" + sites[0].id);
        return;
      } else {
        const { data, error } = await supabase
          .from("profiles")
          .update({ categories: categories })
          .eq("id", getID())
          .select("categories");

        if (error) {
          throw new Error("Couldn't Update Categories!");
        }
        set({ profile: { ...profile, categories: data[0].categories } });
        await fetchCategorySites(data[0].categories);

        // set({ categories: data[0].categories });
        console.log("CATEGORY STUFF", data[0]);
        // usefulToast({
        //   title: "Categories Updated",
        //   description: "Your categories have been updated successfully!",
        //   status: "success",
        //   duration: 9000,
        //   isClosable: true,
        // });
        navigateTo("discover");
      }
    } catch (e) {
      usefulToast({
        title: e?.message || "Error Updating Categories",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  },

  getOtherProfile: async (setProfileUser, setIsLoading, username) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    let profileUser = null;
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("username", username);

      if (error) {
        throw new Error("Couldn't get profile!");
      }

      if (data.length === 0) {
        throw new Error("Couldn't get profile!");
      }

      setProfileUser(data[0]);
      console.log("getOtherProfile:", data[0]);
      toast({
        title: "Profile Fetched",
        description: "The profile has been fetched successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      profileUser = data[0];
    } catch (e) {
      usefulToast({
        title: e?.message || "Error Fetching Profile",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      return profileUser || {};
    }
  },

  getOtherLikedSites: async (id, setIsLoading, setLikedSites) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("id")
        .eq("username", id)
        .single();

      console.log("USERNAME, ERROR, INPUT", data, error, id);
      if (error) {
        throw new Error("Error getting id");
      }

      const { data: likedSitesData, error: likedSitesError } = await supabase
        .from("liked_sites")
        .select("*")
        .eq("user_id", data?.id)
        .order("created_at", { ascending: false })
        .limit(50);

      if (likedSitesError) {
        throw new Error("Error getting liked sites");
      }

      const siteIds = likedSitesData?.map((item) => item.site);

      const { data: websitesData, error: websitesError } = await supabase
        .from("websites")
        .select("*")
        .in("id", [...new Set(siteIds.filter((e) => e !== null))])
        .eq("status", "approved");

      if (websitesError) {
        throw new Error("Error getting websites");
      }

      setLikedSites(websitesData.map((item) => item));
      toast({
        title: "Liked Sites Fetched",
        description: "The liked sites have been fetched successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      // usefulToast({
      //   title: e?.message || "Error Fetching Liked Sites",
      //   status: "error",
      //   duration: 9000,
      //   isClosable: true,
      // });
    } finally {
      setIsLoading(false);
    }
    //       const userData = await supabase
    //         .from("profiles")
    //         .select("id")
    //         .eq("username", id)
    //         .single();
    //       console.log("test'test'test", userData);
    //       let dummy_id = userData.data.id;
    //       id = dummy_id;
    //     } catch {
    //       console.log("error getting id");
    //     }

    //   const likedSiteData = await supabase
    //     .from("liked_sites")
    //     .select("site")
    //     .eq("user_id", id);
    //   // Extract the site IDs from the fetched data
    //   const siteIds = likedSiteData?.data.map((item) => item.site);

    //   // Fetch corresponding websites' data using site IDs
    //   const { data: websitesData, error } = await supabase
    //     .from("websites")
    //     .select("site")
    //     .in("id", [...new Set(siteIds.filter((e) => e !== null))]);

    //   if (error) {
    //     throw new Error("Error fetching websites: " + error.message);
    //   }

    //   // Extract the website values from the fetched websites' data
    //   setProfileLikedSites((await websitesData)?.map((item) => item.site));
    // } catch (error) {
    //   console.error("Error fetching user liked sites with websites:", error);
    //   return [];
    // }
  },

  getOtherCategories: async (profileData) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    if (!profileData || !profileData.categories) {
      set({ categoryIDs: [] });
      return;
    }

    let categoriesData = profileData.categories;
    console.log("CATEGORY DATA FOR PROFILE: ", categoriesData);

    try {
      const { data: categories, error } = await supabase
        .from("categories")
        .select("name")
        .in("id", categoriesData);

      console.log("FINAL CATEGORIES TO DISPLAY: ", categories);
      console.log(error);
      if (error) {
        throw new Error("Error getting categories");
      }

      console.log("getCategories:", categories);
      return categories.map((e) => e.name);
    } catch (error) {
      usefulToast({
        title: error?.message || "Error Getting Categories",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    return [];
  },

  updateProfile: async (upsertedData, setIsSaving, setEdit, setProfileUser) => {
    setIsSaving(true);
    const isLoggedIn = get().isLoggedIn;
    const navigateTo = useAppStore.getState().navigateTo;
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;
    const getID = get().getID;
    const setUser = get().setUser;

    if (upsertedData?.username) {
      upsertedData.username = upsertedData.username.split(" ").join("");
    }

    // try {
    if (!(await isLoggedIn())) {
      throw new Error("Cannot Update Profile if Logged Out!");
    }

    const userId = getID();
    const updates = {
      id: userId,
      ...upsertedData,
    };

    if (upsertedData.banner) {
      const bannerKey = await uploadImageToStorage(
        userId,
        upsertedData.banner,
        "banner"
      );
      console.log(bannerKey);
      if (bannerKey) {
        const bannerUrl = await getSignedImageUrl(bannerKey);
        updates.banner = bannerUrl;
      }
    }

    if (upsertedData.avatar_url) {
      const avatarKey = await uploadImageToStorage(
        "userId",
        upsertedData.avatar_url,
        "avatar"
      );
      if (avatarKey) {
        const avatarUrl = await getSignedImageUrl(avatarKey);
        updates.avatar_url = avatarUrl;
      }
    }

    let { data, error } = await supabase
      .from("profiles")
      .update(updates)
      .eq("id", getID())
      .select("*");

    if (error) {
      throw error;
    }

    setProfileUser(data[0]);
    usefulToast({
      title: "Profile Updated",
      description: "Your profile has been updated successfully!",
      status: "success",
      duration: 9000,
      isClosable: true,
    });

    set({ profile: data[0] });
    setEdit(false);
    window.location.reload();
    // } catch (error) {
    //   usefulToast({
    //     title: error?.message || "Error Updating Profile",
    //     status: "error",
    //     duration: 9000,
    //     isClosable: true,
    //   });
    // } finally {
    //   setIsSaving(false);
    //   setEdit(false);
    // }
  },

  getSitesFromIDs: async (sites) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    try {
      const { data, error } = await supabase
        .from("websites")
        .select("*")
        .eq("status", "approved")
        .in("id", sites);

      if (error) {
        throw new Error("Error getting sites");
      }

      toast({
        title: "Sites Fetched",
        description: "The sites have been fetched successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      console.log("getSitesFromIDs:", data);
      return data;
    } catch (e) {
      usefulToast({
        title: e?.message || "Error Fetching Sites",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  },

  deleteCategory: async (categoryID, setIsLoading) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;
    const getID = get().getID;
    const isLoggedIn = get().isLoggedIn;
    const categories = get().profile.categories;
    const profile = get().profile;
    const convertNameToID = useCategoriesStore.getState().convertNameToID;

    if (!isLoggedIn()) {
      return;
    }

    try {
      setIsLoading(true);
      const id = await convertNameToID(categoryID);
      console.log("id to del:", id);
      console.log(categories, categoryID);
      const { data, error } = await supabase
        .from("profiles")
        .update({
          categories: categories
            .map((e) => Number(e))
            .filter((e) => e !== Number(id)),
        })
        .eq("id", getID());

      console.log(
        categories.map((e) => Number(e)).filter((e) => e !== Number(id))
      );

      console.log("item to deldddd", id);
      set({
        profile: {
          ...profile,
          categories: categories
            .map((e) => Number(e))
            .filter((e) => e !== Number(categoryID)),
        },
      });

      if (error) {
        throw new Error("Error deleting category from profile");
      }

      toast({
        title: "Category Deleted",
        description: "The category has been deleted successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      console.log("deleteCategory:", data);
    } catch (e) {
      usefulToast({
        title: e?.message || "Error Deleting Category",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  },
  isUsernameUnique: async (username) => {
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    try {
      const { data, error } = await supabase
        .from("profiles")
        .select("username")
        .eq("username", username);

      if (error) {
        throw new Error("Error checking username");
      }

      if (data.length > 0) {
        console.log("There was a match in the username: ", data);
        return false;
      } else {
        console.log("There was no match in the username: ", data);
        return true;
      }
    } catch (e) {
      usefulToast({
        title: e?.message || "Error Checking Username",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
  },
  profile: {},
  categories: [],
  categoryIDs: null,
  likedSites: [],
}));

export default useUserStore;
