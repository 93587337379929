import { create } from "zustand";

import { supabase, useAppStore, useSiteStore, useUserStore } from "./index";

const useAdminStore = create((set, get) => ({
  isLoading: false,
  pendingSites: [],
  setPendingSites: (pendingSites) => set({ pendingSites }),
  getPendingSites: async () => {
    const useAppState = useAppStore.getState();
    const toast = useAppState.usefulToast;
    const isAdmin = useUserStore.getState().isAdmin;

    if (!isAdmin()) {
      toast({
        title: "You are not an admin",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      useAppState.navigateTo("");
      return;
    }
    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from("websites")
        .select("*")
        .in("status", ["pending", "reported"]);

      if (error) {
        throw error;
      }

      set({ pendingSites: data });
      console.log("Sites Awaiting to be Approved: ", data);
    } catch (e) {
      toast({
        title: e?.message || "Error Fetching Pending Sites",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },
  filterPendingSites: (siteId) => {
    set({
      pendingSites: useAdminStore
        .getState()
        .pendingSites.filter((site) => site.id !== siteId),
    });
  },
  approveSite: async (siteId) => {
    const toast = useAppStore.getState().usefulToast;
    try {
      if (!siteId) {
        throw new Error("Site ID is required");
      }

      const isAdmin = useUserStore.getState().isAdmin;

      if (!isAdmin()) {
        throw new Error("You are not an admin");
      }

      set({ isLoading: true });
      const { data, error } = await supabase
        .from("websites")
        .update({ status: "approved" })
        .select("*")
        .eq("id", siteId);

      if (error) {
        throw error;
      }

      console.log("Site Approved: ", data);
      get().filterPendingSites(siteId);
      toast({
        title: "Site Approved",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e?.message || "Error Approving Site",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },
  disapproveSite: async (siteId) => {
    const toast = useAppStore.getState().usefulToast;
    try {
      if (!siteId) {
        throw new Error("Site ID is required");
      }

      const isAdmin = useUserStore.getState().isAdmin;
      if (!isAdmin) {
        throw new Error("You are not an admin");
      }

      set({ isLoading: true });
      const { data, error } = await supabase
        .from("websites")
        .update({ status: "disapproved" })
        .select("*")
        .eq("id", siteId);

      if (error) {
        throw error;
      }

      console.log("Site Disapproved: ", data);
      get().filterPendingSites(siteId);
      toast({
        title: "Site Disapproved",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e?.message || "Error Disapproving Site",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },
  addCategory: async (name, setIsLoading) => {
    try {
      setIsLoading(true);

      const isAdmin = useUserStore.getState().isAdmin;
      const usefulToast = useAppStore.getState().usefulToast;

      if (!isAdmin()) {
        throw new Error("You are not an admin");
      }

      const { data, error } = await supabase
        .from("categories")
        .insert([{ name: name }]);
      if (error) {
        throw new Error("Error adding category");
      }

      console.log("Category Added: ", data);
      usefulToast({
        title: "Category Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      usefulToast({
        title: error?.message || "Error adding category",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  },
  addSubCategory: async (name, id, setIsLoading) => {
    console.log("adding category");
    try {
      setIsLoading(true);
      console.log("adding subscategory");
      const isAdmin = useUserStore.getState().isAdmin;
      const usefulToast = useAppStore.getState().usefulToast;

      if (!isAdmin()) {
        throw new Error("You are not an admin");
      }

      const { data, error } = await supabase
        .from("categories")
        .insert([{ parent_id: id, name: name }]);
      if (error) {
        throw new Error("Error adding subcategory");
      }

      console.log("Subcategory Added: ", data);
      usefulToast({
        title: "Subcategory Added",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      usefulToast({
        title: error?.message || "Error adding subcategory",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  },
  removeSite: async (siteId) => {
    const toast = useAppStore.getState().usefulToast;
    const extraUsefulToast = useAppStore.getState().extraUsefulToast;

    try {
      if (!siteId) {
        throw new Error("Site ID is required");
      }

      const isAdmin = useUserStore.getState().isAdmin;
      if (!isAdmin()) {
        throw new Error("You are not an admin");
      }

      set({ isLoading: true });
      const { data, error } = await supabase
        .from("websites")
        .delete("*")
        .select("*")
        .eq("id", siteId);

      if (error) {
        throw error;
      }

      console.log("Site Deleted: ", data);
      useSiteStore.getState().setCurrent();
      get().filterPendingSites(siteId);
      extraUsefulToast({
        title: "Site Deleted",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e?.message || "Error Deleting Site",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      set({ isLoading: false });
    }
  },
  reportSite: async (siteId) => {
    const toast = useAppStore.getState().usefulToast;
    const isLoggedIn = useUserStore.getState().isLoggedIn;
    const onAuthModalOpen = useAppStore.getState().authModal.onOpen;
    const onAuthModalClose = useAppStore.getState().authModal.onClose;
    const setCurrent = useSiteStore.getState().setCurrent;

    // onAuthModalOpen();

    try {
      if (!siteId) {
        throw new Error("Site ID is required");
      }

      if (!isLoggedIn()) {
        onAuthModalOpen();
        throw new Error("You are not logged in");
      }

      set({ isLoading: true });
      const { data, error } = await supabase
        .from("websites")
        .update({ status: "reported" })
        .select("*")
        .eq("id", siteId);

      if (error) {
        throw error;
      }

      console.log("Site Reported: ", data);
      get().filterPendingSites(siteId);
      setCurrent();
      toast({
        title: "Site Reported",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e?.message || "Error Reporting Site",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log("[reportSite()] - You are not logged in");
    } finally {
      set({ isLoading: false });
    }
  },
  contactOwner: async (siteId) => {
    const toast = useAppStore.getState().usefulToast;

    try {
      const isAdmin = useUserStore.getState().isAdmin;
      if (!isAdmin()) {
        throw new Error("You are not an admin");
      }

      if (!siteId) {
        throw new Error("Site ID is required");
      }

      const getOwnerEmail = useUserStore.getState().getOwnerEmail(siteId);
      const ownerEmail = await getOwnerEmail;

      if (!ownerEmail) {
        throw new Error("Couldn't get owner email");
      }

      // Open in mailing client
      window.open(`mailto:${ownerEmail}`, "_blank");
      toast({
        title: "Contacting Owner",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: e?.message || "Error Contacting Owner",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  },
  // Not a priority, get to do this later
  banUser: async (userId) => {},
  unbanUser: async (userId) => {},
  removeUser: async (userId) => {},
}));

export default useAdminStore;
