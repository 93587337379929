import { useEffect, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Heading,
  CardFooter,
  Flex,
  CardHeader,
} from "@chakra-ui/react";
import { useAppStore, useCategoriesStore } from "../../stores";

import { copy as C } from "../../configs/copy";
import { motion } from "framer-motion";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

/*
 * This component are for admins to access some necessary actions.
 * It is a card that is always on the bottom left of the screen.
 */
export default function AdminCard({ info, actions }) {
  const navigateTo = useAppStore((state) => state.navigateTo);
  const [categories, setCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].adminCard;
  const getSiteCategories = useCategoriesStore(
    (state) => state.getSiteCategories
  );

  useEffect(() => {
    const asyncf = async () => {
      console.log("current site", info.id);
      const categories = await getSiteCategories(info.id);
      setCategories(categories);
      console.log(categories);
    };
    asyncf();
  }, [info]);

  const variants = {
    open: { x: 0 },
    closed: { x: "-100%" },
  };

  return (
    <Flex pos="fixed" bottom="11.8vh" left="-5" minW="300px">
      {isOpen && (
        <motion.div
          initial="closed"
          animate="open"
          exit="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
          style={{
            position: "absolute",
            top: -266,
            left: "0",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            bg="white"
            rounded="md"
            borderWidth="2px"
            borderColor="blue.500"
            w="sm"
            h="295px"
            roundedBottom={"0"}
            roundedTopLeft={"0"}
            borderLeft="0"
          >
            <CardHeader>
              <Flex justify="space-between" align="center">
                <Heading fontSize="lg" ml="7">
                  {copy.adminCard}
                </Heading>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Button
                    onClick={() => setIsOpen(false)}
                    bg="orange.500"
                    color="white"
                    _hover={{ bg: "orange.600" }}
                    _active={{ bg: "orange.700" }}
                    _focus={{ boxShadow: "none" }}
                    style={{
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <AiOutlineArrowLeft />
                  </Button>
                </motion.div>
              </Flex>
            </CardHeader>
            <CardBody ml="3">
              <Flex gap="4" flexDir={"column"}>
                <Box>
                  {info?.id}. {info?.site.substring(0, 30) || info?.username}
                </Box>
                <ButtonGroup w="100%" isAttached>
                  {actions.map((action, index) => (
                    <Button
                      key={`action+${index}`}
                      onClick={action[Object.keys(action)[0]]}
                      w="100%"
                    >
                      {Object.keys(action)[0]}
                    </Button>
                  ))}
                </ButtonGroup>
              </Flex>
            </CardBody>
            <CardFooter>
              <ButtonGroup w="100%" ml="3">
                {[...Array(2)].map((_, index) => (
                  <Button
                    key={`category+${index}`}
                    bg="orange.500"
                    color="white"
                    _hover={{ bg: "orange.600" }}
                    _active={{ bg: "orange.700" }}
                    _focus={{ boxShadow: "none" }}
                    w="50%"
                  >
                    {categories[index]?.name || "n/a"}
                  </Button>
                ))}
              </ButtonGroup>
            </CardFooter>
          </Card>
        </motion.div>
      )}
      {!isOpen && (
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button
            onClick={() => setIsOpen(true)}
            bg="orange.500"
            color="white"
            _hover={{ bg: "orange.600" }}
            _active={{ bg: "orange.700" }}
            _focus={{ boxShadow: "none" }}
            style={{ position: "relative", zIndex: 1, right: -20, top: 23 }}
            roundedLeft={"0"}
            roundedBottom={"0"}
            roundedBottomRight={"0"}
          >
            <AiOutlineArrowRight />
          </Button>
        </motion.div>
      )}
    </Flex>
  );
}
