import supabase from "../stores/supaStore";
import { SUPABASE_KEYS } from "../configs/secrets";

export function dataURLtoBlob(dataURL) {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export async function uploadImageToStorage(userId, file, type) {
  const bucketName = "avatars"; // Replace with your actual bucket name
  const key = `${userId}-${type}.webp`;

  const imageBlob = typeof file === "string" ? dataURLtoBlob(file) : file;

  const { data, error } = await supabase.storage
    .from(bucketName)
    .upload(`avatars/${key}`, imageBlob, { upsert: true });

  if (error) {
    console.error("Error uploading image:", error);
    return null;
  }

  return key; // Return the key of the uploaded file
}

export async function getSignedImageUrl(key) {
  const { data, error } = await supabase.storage
    .from("avatars") // Replace with your actual bucket name
    .createSignedUrl("avatars/" + key, 999999999999999999999999999999999); // URL validity in seconds (5 minutes in this example)

  if (error) {
    console.error("Error generating signed URL:", error);
    return null;
  }

  return data.signedUrl;
}

export function generateImageUrl(key) {
  const publicUrl = `${SUPABASE_KEYS.dbUrl}/storage/v1/object/public/avatars/${key}`;
  return publicUrl;
}
