import { Input } from "@chakra-ui/react";
import SizeIncreaseHover from "./SizeIncreaseHover";
import { colours } from "../configs/styles";

export default function GeneralInput({ placeholder, value, useSet }) {
  return (
    <Input
      placeholder={placeholder}
      value={value}
      bg={colours.text.primary}
      onChange={(e) => useSet(e.target.value)}
    />
  );
}
