import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { EMAIL_JS_KEYS } from "../../configs/secrets";

import {
  Button,
  Flex,
  Textarea,
  useToast,
  Input,
  Select,
} from "@chakra-ui/react";

import { useAppStore } from "../../stores";

import { MainPage } from "../../components";
import { SizeIncreaseHover } from "../../UI";
import { colours } from "../../configs/styles";
import { copy as C } from "../../configs/copy";


/*
* This component is used to send an email to the main email of the website.
* Here we add things like feature requests, bug reports, sponsorship requests, etc.
*/
export const ContactUs = () => {
  const { navigateTo, isMobile } = useAppStore((state) => state);
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].emailUs;
  const reasons = [
    "Sponsorship",
    "General question",
    "Feature request",
    "Report a bug",
  ];

  useEffect(() => {
    if (window.location.href.split("=")[1] === "sponsorship") {
      form.current.reason.value = "Sponsorship";
    }
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {
      await emailjs.sendForm(
        EMAIL_JS_KEYS.service,
        EMAIL_JS_KEYS.template,
        form.current,
        EMAIL_JS_KEYS.public
      );

      toast({
        title: copy.toastEmailSent,
        description: copy.toastEmailSentDescription,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsSubmitting(false); // Reset submitting state
      setTimeout(() => {
        navigateTo("discover");
      }, 3000); // Delay in milliseconds
    } catch (error) {
      console.log(error.text);
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <MainPage top="-60" title="Contact" justify="left" desktopWidth="30%" labelText={'You can send us an email for support, sponsorships & much more.'}>
      <Flex align="center" justify="center" flexDirection="column">
        <Flex w={isMobile ? "100%" : "100%"}>
          <form ref={form} onSubmit={sendEmail} style={{ width: "100vw" }}>
            <SizeIncreaseHover>
              <Input
                type="text"
                name="user_name"
                bg="white"
                placeholder={copy.nameInputPlaceholder}
                mb={4}
                disabled={isSubmitting} // Disable input while submitting
                required
              />
            </SizeIncreaseHover>
            <SizeIncreaseHover>
              <Input
                type="email"
                name="user_email"
                bg="white"
                placeholder={copy.emailInputPlaceholder}
                mb={4}
                disabled={isSubmitting} // Disable input while submitting
                required
              />
            </SizeIncreaseHover>
            <SizeIncreaseHover>
              <Textarea
                type="text"
                name="message"
                bg="white"
                placeholder={"Enter your message"}
                mb={4}
                disabled={isSubmitting} // Disable input while submitting
                required
              />
            </SizeIncreaseHover>
            <SizeIncreaseHover>
              <Select
                bg={colours.text.primary}
                name="reason"
                mb="4"
                required
                defaultValue=""
              >
                <option value="" disabled>
                  Select Reason
                </option>
                {reasons.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </SizeIncreaseHover>
            <Button
              bg={colours.action.primary}
              type="submit"
              isLoading={isSubmitting}
            >
              {copy.sendEmail}
            </Button>
          </form>
        </Flex>
      </Flex>
    </MainPage>
  );
};

export default ContactUs;
