import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Heading, Flex } from "@chakra-ui/react";

import { useAppStore } from "../../stores";

export const LikedSiteCard = ({ site }) => {
  const { navigateTo } = useAppStore((state) => state);
  console.log(site);
  return (
    <Flex
      w="100%"
      _hover={{ cursor: "pointer" }}
      p="2"
      align="center"
      justify="center"
      bg="gray.100"
      rounded="md"
      shadow="base"
      onClick={() => navigateTo("discover/" + site.id)}
    >
      <Heading fontSize="sm">{site.site.substring(0, 30)}</Heading>
      <Flex ml="auto" align="center">
        <ExternalLinkIcon />
      </Flex>
    </Flex>
  );
};
