import { Flex, Text, Box, useBreakpointValue } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";

const Plus1Cookie = ({ cookies }) => {
  return (
    <Flex position="fixed" top={0} left={0} zIndex={100000}>
      <AnimatePresence>
        {cookies.map((cookie) => (
          <CookiePopup key={cookie.id} />
        ))}
      </AnimatePresence>
    </Flex>
  );
};

const CookiePopup = () => {
  const cookieBreakPoint = useBreakpointValue({ base: 30, sm: 50, md: 40 });
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0, y: 0, x: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
        y: -Math.floor(Math.random() * cookieBreakPoint),
        x: 0,
      }}
      exit={{ scale: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        top: cookieBreakPoint + "vh",
        left: "10vh",
        justifyContent: "center",
        color: "black",
        width: "100vw",
        height: "100vh",
        pointerEvents: "none",
        borderRadius: "50%",
      }}
    >
      <Text fontSize="xl" color="black">
        +{"1"}
      </Text>
      <Box style={{ fontSize: "20px", transform: "translateX(-50%)" }}>🍪</Box>
    </motion.div>
  );
};

export default Plus1Cookie;
