import axios from "axios";

function ValidateEmail(input) {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (input.match(validRegex)) {
    return true;
  } else {
    alert("Invalid email address!");
    return false;
  }
}

const saveEmail = async (
  setLoading,
  setError,
  email,
  setComplete
) => {
  if (!ValidateEmail(email)) {
    setLoading(false)
    return true;
  }

  setLoading(true);
  const encodedEmail = encodeURIComponent(email);
  const encodedUrl = encodeURIComponent(`https://kensavage.activehosted.com/proc.php?f=4&c=0&m=0&act=sub&v=2&email=${encodedEmail}&jsonp=true`)
  const url = `https://corsproxy.io/?url=${encodedUrl}`;
  const options = {
    method: "GET",
    url
  };

  axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
      localStorage.setItem("email", email);
      setComplete(true);
    })
    .catch(function (error) {
      console.error(error);
      setError(true);
    }).finally(() => {
      setLoading(false);
    });
};

export default saveEmail;
