import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import { Flex, useBreakpointValue, useToast } from "@chakra-ui/react";

import {
  WebsiteWindow,
  NavBar,
  AdminCard,
  SiteInfoCard,
} from "../../components";
import { SizeIncreaseHover } from "../../UI";
import CategoryItemExploration from "./CategoryItemExplorePage";

import {
  useAppStore,
  useSiteStore,
  useAdminStore,
  useUserStore,
} from "../../stores";

export default function HomePage() {
  const { id } = useParams();
  const { getParamSite } = useSiteStore((state) => state);
  const { navigateTo, toast } = useAppStore((state) => state);
  const currentSite = useSiteStore((state) => state.current);
  const infoToast = useToast();
  const { removeSite } = useAdminStore((state) => state);
  const { isAdmin } = useUserStore((state) => state);
  const [isCategorDiscoverPage, setIsCategoryDiscoverPage] = useState(false);

  useEffect(() => {
    if (id) {
      if (!isNaN(Number(id))) {
        getParamSite(id);
        console.log("id is not none");
      } else {
        setIsCategoryDiscoverPage(true);
      }
    } else {
      console.log("bug causer");
      navigateTo("discover/" + (currentSite?.id || 8942));
    }
  }, [id]);

  useEffect(() => {
    if (
      !localStorage.getItem("visited") &&
      id !== undefined &&
      !isNaN(Number(id))
    ) {
      infoToast({
        title: "If you find these sites delicious, give them a thumbs up!",
        description: "All your liked sites are saved on your profile.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      localStorage.setItem("visited", true);
    }
  }, [id]);

  // Mobile Styling
  const fixedBar = useBreakpointValue({ base: "fixed", md: "" });

  return (
    <>
      <Helmet>
        <title>{currentSite.title}</title>
        <meta property="og:title" content={currentSite.title} />
        <meta property="og:description" content={currentSite.description} />
        <meta property="og:image" content={currentSite.snapshot_url} />
      </Helmet>
      {isCategorDiscoverPage ? (
        <CategoryItemExploration
          setIsCategoryDiscoverPage={setIsCategoryDiscoverPage}
        />
      ) : (
        <Flex flexDir={"column"} w="100vw" h="100vh">
          <WebsiteWindow site={currentSite?.site || false} />
          <Flex
            pos={"fixed"}
            bottom={fixedBar == "fixed" ? "0" : "0"}
            zIndex="2"
            w="100vw"
            flexDir="column"
          >
            <NavBar
              commentCard={
                <SizeIncreaseHover>
                  <SiteInfoCard
                    comments={currentSite?.comments || []}
                    site={currentSite}
                  />
                </SizeIncreaseHover>
              }
            />
            {isAdmin() && (
              <AdminCard
                info={currentSite}
                stats={currentSite}
                actions={[
                  { remove: () => removeSite(currentSite.id) },
                  { edit: () => navigateTo("admin/edit/" + currentSite.id) },
                ]}
              />
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
}
