import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Heading,
  Card,
  CardHeader,
  Grid,
  Avatar,
  Text,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { colours } from "../../configs/styles";

import { MainPage } from "../../components";
import { copy as C } from "../../configs/copy";

import { useAppStore, useLeaderboardStore } from "../../stores";

export default function Leaderboard() {
  const { isMobile, navigateTo } = useAppStore((state) => state);
  const {
    userCoins,
    userLikes,
    sites: websites,
  } = useLeaderboardStore((state) => state);
  const { getUserCoins, getUserLikes, getSites } = useLeaderboardStore(
    (state) => state
  );

  const [users, setUsers] = useState([]);
  const [usersCookies, setUsersCookies] = useState([]);
  const [sites, setSites] = useState([]);

  const lang = useAppStore((state) => state.language);
  const copy = C[lang].leaderboardPage;

  useEffect(() => {
    const asyncf = async () => {
      await getUserLikes();
      await getSites();
      await getUserCoins();
    };

    asyncf();
  }, []);

  useEffect(() => {
    setUsers(
      userLikes.map((user) => (
        <>
          <BoardCardItem
            name={user.username}
            valueString={user.like_count.toLocaleString() + " likes"}
            avatar={user.avatar_url}
            pageTo={"/profile/@" + user.username}
          />
        </>
      ))
    );

    setSites(
      websites.map((site) => (
        <BoardCardItem
          name={site.site
            .replace("https://", "")
            .replace("http://", "")
            .replace("/", "")}
          valueString={`${site.views} views`}
          avatar={site.snapshot_url}
          imageType="md"
          pageTo={"/discover/" + site.id}
        />
      ))
    );

    setUsersCookies(
      userCoins.map((user) => (
        <BoardCardItem
          name={user.username}
          valueString={user.coins.toLocaleString() + " coins"}
          avatar={user.avatar_url}
          pageTo={"/profile/@" + user.username}
        />
      ))
    );
  }, [userLikes, websites, userCoins]);

  return (
    <MainPage
      top="-60"
      title="Leaderboard"
      justify="center"
      desktopWidth={"fit-content"}
      labelText={"These are the top performers on CookieJar. If you like enough, you might end up at the top!"}
    >
      <Grid
        w="100%"
        templateColumns={!isMobile ? "repeat(3, 1fr);" : "repeat(1, 1fr);"}
        gap="5"
      >
        {isMobile && (
          <Tabs>
            <TabList>
              <Tab>{copy.userLiked}</Tab>
              <Tab>{copy.websiteLikes}</Tab>
              <Tab>{copy.userCookiePoints}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Board items={users}></Board>
              </TabPanel>
              <TabPanel>
                <Board items={sites}></Board>
              </TabPanel>
              <TabPanel>
                <Board items={usersCookies}></Board>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {!isMobile && (
          <>
            <Board items={sites}>
              <Heading as="h3" fontSize="xl">
                {copy.websiteLikes}
              </Heading>
            </Board>
            <Board items={users}>
              <Heading as="h3" fontSize="xl">
                {copy.userLiked}
              </Heading>
            </Board>
            <Board items={usersCookies}>
              <Heading as="h3" fontSize="xl">
                {copy.userCookiePoints}
              </Heading>
            </Board>
          </>
        )}
      </Grid>
    </MainPage>
  );
}

export const Board = ({ items, children }) => {
  return (
    <Flex w="100%" h="fit-content" align="center" gap="2" flexDir="column">
      {children}
      {items.map((item, index) => (
        <Flex w="100%">
          <Boardcard
            key={index + item + Math.random() * 10000000}
            position={index}
            contents={item}
          />
        </Flex>
      ))}
    </Flex>
  );
};

const Boardcard = ({ position, contents }) => {
  return (
    <Card
      w="100%"
      maxW="500px"
      border={`${
        position == 0
          ? colours.action.primary
          : position == 1
          ? colours.primary
          : position == 2
          ? "blue"
          : "lightgrey"
      } 2px solid`}
    >
      <CardHeader>
        <Flex textAlign={"center"} align="center" h="100%" w="100%">
          <Heading as="h1" size="md" mr="2">
            {position + 1}.
          </Heading>
          {contents}
        </Flex>
      </CardHeader>
    </Card>
  );
};

const BoardCardItem = ({
  position,
  name,
  valueString,
  avatar,
  pageTo,
  imageType = "full",
}) => {
  return (
    <Flex
      _hover={{ cursor: "pointer" }}
      h="40px"
      w="100%"
      sx={{ textDecoration: "none" }}
      align="center"
      minW="250px"
    >
      {imageType == "full" ? (
        <Avatar src={avatar} name={name} mr="2" rounded={imageType} />
      ) : (
        <Image
          src={avatar}
          name={name}
          mr={"2"}
          rounded={imageType}
          h="48px"
          w="47px"
        />
      )}
      <Flex flexDir="column" align={"center"}>
        <Text fontSize="md" fontWeight="bold" lineHeight={"16px"} mr="auto">
          <Link to={pageTo}>
            {position} {name.substring(0, 20)}
          </Link>
        </Text>

        <Text fontSize="sm" color="gray.500" lineHeight={"16px"} mr="auto">
          {valueString}
        </Text>
      </Flex>
    </Flex>
  );
};
