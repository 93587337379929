/* 
All the keys & secrets provided here are available to use for the public.
This is because the API keys are not sensitive and are only used to
do tasks such as sending emails, and other things that are not sensitive.
Even if the api gets abused, there will be proer rate limits in the future
*/

export const EMAIL_JS_KEYS = {
    service: 'service_3fx9yvr',
    template: 'template_no67rs8',
    public: 'CaJOJpsMV7iuCquNE'
}

export const SUPABASE_KEYS = {
    dbUrl: "https://iamsnnsvbvwgupxuycjm.supabase.co",
    dbAnonKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlhbXNubnN2YnZ3Z3VweHV5Y2ptIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTE2NDE1MDksImV4cCI6MjAwNzIxNzUwOX0.J8_6gQPYrWqeDDonMmClXMYH8F2h9vKbPZBGHigUMn0"
}