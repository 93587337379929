import { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import AuthHandler from "./AuthHandler";
import { colours } from "../../configs/styles";
import { ThemeSupa } from "@supabase/auth-ui-shared";

import { useAppStore, useUserStore } from "../../stores";

import { useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
export default function AuthModal({ isReferral, referralParams }) {
  const { setAuthModal } = useAppStore((state) => state);
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const auth_view = useUserStore((state) => state.auth_view);
  const location = useLocation(); // Get the current location pathname

  useEffect(() => {
    setAuthModal({ onOpen, onClose, isOpen });
  }, []);

  useEffect(() => {
    // Check if the pathname is "confirm-email" and close the modal if it is
    if (location.pathname === "/confirm-email") {
      onClose();
    }
  }, [location.pathname]);

  const config = {
    appearance: {
      theme: ThemeSupa,
      style: {
        button: { background: colours.primary, border: "none" },
      },
    },
    localization: {
      variables: {
        sign_in: {
          email_label: "Email",
          password_label: "Password",
          // Make the button say login
          button_label: "Login",
          link_text: "Have an Account? Login here",
        },
        sign_up: {
          email_label: "Email",
          password_label: "Password",
          // Make the button say sign up
          button_label: "Register",
          link_text: "Don't have an account? Register here",
        },
      },
    },
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg={colours.secondary}>
          <ModalHeader>
            {auth_view == "sign_in" ? "Login" : "Register"}
          </ModalHeader>
          <ModalCloseButton />
          <AuthHandler config={config} />
        </ModalContent>
      </Modal>
    </>
  );
}
