import React from 'react';
import { motion } from 'framer-motion';

const SizeIncreaseHover = ({children}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
        {children}
    </motion.div>
  );
};

export default SizeIncreaseHover;
