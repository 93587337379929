// EmailCaptureForm.js
import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner,
  Text,
  Flex,
  Card,
  CardHeader,
  CardBody,
  Image,
} from "@chakra-ui/react";

import saveEmail from "../../functions/emailcapture";
import { colours } from "../../configs/styles";
import logo from "../../assets/logo.svg";
import { copy as C } from "../../configs/copy";
import { SizeIncreaseHover, GeneralInput } from "../../UI";

import { useAppStore } from "../../stores";
import { DeprecatedLayoutGroupContext } from "framer-motion";

/*
 * This component is used to capture the user's email.
 * This component isn't in use anymore
 * 
 * @Deprecated
 */

export default function EmailCaptureForm({ header, children }) {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [complete, setComplete] = useState(false);
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].emailCaptureForm;

  useEffect(() => {
    const email = localStorage.getItem("email") || null;
    if (email) {
      setComplete(true);
    }
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);

    if (saveEmail(setIsLoading, setError, email, setComplete)) {
      console.log("error");
    }

    if (!error) {
      localStorage.setItem("email", email);
      setEmail(email);
    }
  };

  return (
    <Card
      direction="column"
      alignItems="center"
      justifyContent="center"
      bg="rgba(255, 255, 255, 0.2)"
      backdropFilter="auto"
      backdropBlur="15px"
      border="1px solid rgba(255, 255, 255, 0.5)"
    >
      <Image src={logo} h="55px" w="55px" mr="auto" ml="2" mt="3" alt="Logo"/>
      <CardHeader>{header}</CardHeader>
      <CardBody w="100%" p="4">
        {complete ? (
          <Flex w="98%">{children}</Flex>
        ) : (
          <>
            {isLoading ? (
              <Flex w="100%" flexDir="column">
                <CustomSpinner />{" "}
              </Flex>
            ) : error ? (
              <Text>{copy.thereWasAnError}</Text>
            ) : (
              <>
                <FormControl w="100%">
                  <FormLabel w="100%">{copy.email}</FormLabel>
                  <SizeIncreaseHover>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                    />
                  </SizeIncreaseHover>
                </FormControl>
                {!error && !isLoading && (
                  <SizeIncreaseHover>
                    <Button
                      bg={colours.primary}
                      mb={4}
                      mt="4"
                      onClick={handleSubmit}
                    >
                      {copy.save}
                    </Button>
                  </SizeIncreaseHover>
                )}
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
}
