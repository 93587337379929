import { useEffect, useState } from "react";
import { supabase } from "../../stores";

import {
  Flex,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select,
} from "@chakra-ui/react";

import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { colours } from "../../configs/styles";
import { copy as C } from "../../configs/copy";
import { MainPage, AddSiteCard } from "../../components";
import { GeneralInput, CustomSpinner, SizeIncreaseHover } from "../../UI";

import { useAdminStore, useAppStore, useCategoriesStore } from "../../stores";
import { Link } from "react-router-dom";

export default function Admin() {
  const {
    pendingSites,
    getPendingSites,
    isLoading,
    disapproveSite,
    approveSite,
    addSubCategory,
    addCategory,
  } = useAdminStore((state) => state);
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].adminPage;
  const isMobile = useAppStore((state) => state.isMobile);
  const { getAllUniqueMain } = useCategoriesStore((state) => state);
  const [unique, setUnique] = useState([]);
  const [category, setCategory] = useState();
  const [categoryToAdd, setCategoryToAdd] = useState();
  const [isCategoryAddingLoading, setIsCategoryAddingLoading] = useState(false);

  useEffect(() => {
    getPendingSites();
  }, []);

  useEffect(() => {
    const asyncf = async () => {
      const uniques = await getAllUniqueMain();
      setUnique(uniques);
    };
    asyncf();
  }, []);

  return (
    <MainPage
      top="-60"
      title="Admin Panel"
      justify="right"
      desktopWidth={"50%"}
      labelText={'This is the admin panel. Admins can modify & edit content here.'}
    >
      <Tabs isFitted>
        <TabList>
          <Tab>{copy.confirmOrDenySites}</Tab>
          <Tab>{copy.addASite}</Tab>
          <Tab>Add Category</Tab>
        </TabList>
        <TabPanels>
          <TabPanel
            align="center"
            justify="center"
            display="flex"
            flexDir="column"
          >
            {isLoading ? (
              <CustomSpinner />
            ) : (
              pendingSites.map((site) => (
                <Flex key={site.id} w="100%">
                  <Flex flexDir="column">
                    <Link to={site.site} target={"_blank"}>
                      {site.site.substring(0, 20)}
                    </Link>
                    <Flex mr="auto">
                      <ProfileLink user_id={site.user_id} supabase={supabase} />
                    </Flex>
                  </Flex>
                  <Flex gap="2" ml="auto">
                    <Button
                      bg={colours.action.primary}
                      onClick={() => approveSite(site.id)}
                    >
                      <CheckIcon />
                    </Button>
                    <Button
                      bg={colours.action.secondary}
                      onClick={() => disapproveSite(site.id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Flex>
                </Flex>
              ))
            )}
          </TabPanel>
          <TabPanel>
            <AddSiteCard />
          </TabPanel>
          <TabPanel>
            {isCategoryAddingLoading ? (
              <CustomSpinner />
            ) : (
              <Flex flexDir={!isMobile ? "column" : "row"} gap="4" h="100%">
                <Flex justify="space-between" w="100%">
                  <Flex flexDir="column" gap="4" w="45%" mt="auto">
                    <GeneralInput
                      placeholder={"Category Name"}
                      useSet={setCategoryToAdd}
                    />
                    <Button
                      bg={colours.action.primary}
                      onClick={() => {
                        console.log("adding cat");
                        addCategory(categoryToAdd, setIsCategoryAddingLoading);
                      }}
                    >
                      Add Category
                    </Button>
                  </Flex>
                  <Flex flexDir="column" gap="4" w="45%">
                    <SizeIncreaseHover>
                      <Select
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                      >
                        {unique.map((e) => (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        ))}
                      </Select>
                    </SizeIncreaseHover>
                    <GeneralInput
                      placeholder={"Subcategory Name"}
                      useSet={setCategoryToAdd}
                    />
                    <Button
                      bg={colours.action.primary}
                      onClick={() => {
                        console.log("adding subcat");
                        addSubCategory(
                          categoryToAdd,
                          category,
                          setIsCategoryAddingLoading
                        );
                      }}
                    >
                      Add Subcategory
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MainPage>
  );
}

const ProfileLink = ({ supabase, user_id }) => {
  const [userName, setUsername] = useState("");
  useEffect(() => {
    const asyncf = async () => {
      const { data, error } = await supabase
        .from("profiles")
        .select("username")
        .eq("id", user_id);
      if (!error) {
        setUsername(data[0].username);
      }
    };
    asyncf();
  }, []);

  return (
    <Link
      to={"/profile/@" + userName}
      href={"https://" + window.location.host + "/profile/@" + userName}
      target="_blank"
    >
      @{userName}
    </Link>
  );
};
