import { Badge, Box } from "@chakra-ui/react";
import React from "react";

const StatsBadge = ({ icon, stat }) => {
  return (
    <Badge
      p="2"
      minW="120px"
      maxW="150px"
      w="100%"
      display="flex"
      rounded="md"
      justifyContent={"center"}
      alignItems={"center"}
      gap="2"
    >
      <Box fontSize="xs">{icon}</Box> {stat}
    </Badge>
  );
};

export const StatsMemberSinceBadge = ({ icon, stat }) => {
  return (
    <Badge
      p="1"
      minW="125px"
      display="flex"
      rounded="md"
      justifyContent={"center"}
      alignItems={"center"}
      gap="2"
    >
      {icon} <br /> {stat}
    </Badge>
  );
};

export default StatsBadge;
