import {
  Button,
  Text,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image,
} from "@chakra-ui/react";
import { useRef } from "react";
import flag from "../../assets/flag.webp";
import { colours } from "../../configs/styles";
import { SizeIncreaseHover } from "../../UI";
import { useAdminStore, useSiteStore } from "../../stores";

const FlagSite = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const reportSite = useAdminStore((state) => state.reportSite);
  const currentSite = useSiteStore((state) => state.current);

  return (
    <>
      <SizeIncreaseHover>
        <Button
          variant={"solid"}
          size="sm"
          p="2"
          leftIcon={<Image src={flag} h="20px" alt="" />}
          onClick={onOpen}
          bg="white"
        >
          <Text>Flag</Text>
        </Button>
      </SizeIncreaseHover>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg={colours.secondary}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Flag Site
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? It doesn't mean this site will get removed. And
              spamming can lead to a ban!
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                bg={colours.action.primary}
                onClick={() => {
                  reportSite(currentSite.id);
                  onClose();
                }}
                ml={3}
              >
                Flag
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default FlagSite;
