import { Button, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import like from "./assets/like.svg";

/*
 * This is the button that is used to rate a site
 */
export default function RateButton({ onAction, image, color }) {
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <Button
        bg={`${color}`}
        borderRadius={"50%"}
        h={"200px"}
        w={"200px"}
        fontSize="4xl"
        onClick={() => {
          onAction();
        }}
        style={{ width: "50px", height: "50px" }}
      >
        <Image src={like} />
      </Button>
    </motion.div>
  );
}
