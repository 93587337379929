import React, { useEffect, useState } from "react";
import { FormControl, Button, Select, SimpleGrid } from "@chakra-ui/react";
import { colours } from "../../configs/styles";
import { copy as C } from "../../configs/copy";
import { SizeIncreaseHover, GeneralInput } from "../../UI";
import {
  useSiteStore,
  useUserStore,
  useCategoriesStore,
  useAppStore,
} from "../../stores";

/*
  This component is used to add a site to the database.
  Non Admins get to have the site be on a pending state until an admin approves it.
  Admins get to have the site be added to the database instantly.
*/
export default function AddSiteCard({ input = {} }) {
  console.log("input", input);
  const user = useUserStore((state) => state.user);
  const [site, setWebsite] = useState("");
  const [category, setcategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [siteId, setSiteId] = useState(null);
  const [fetchedMainCategories, setFetchedMainCategories] = useState([]);
  const [fetchedSubCategories, setFetchedSubCategories] = useState([]);

  const { requestSiteAdd } = useSiteStore((state) => state);
  const { getAllSubFromMainID, getAllUniqueMain } = useCategoriesStore(
    (state) => state
  );
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].addSite;
  useEffect(() => {
    const asyncf = async () => {
      const mainCategories = await getAllUniqueMain();
      setFetchedMainCategories(mainCategories);

      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        if (!user?.session) {
          console.log("user invalid");
        }
      }, 3000);
    };

    asyncf();
  }, []);

  useEffect(() => {
    if (Object.keys(input).length > 3) {
      setWebsite(input.site);
      setcategory(input.category);
      setSubCategory(input.subcategory);
      setSiteId(input.id);
    }
  }, [input]);

  useEffect(() => {
    const asyncf = async () => {
      const subCategories = await getAllSubFromMainID(category);
      setFetchedSubCategories(subCategories);
    };
    asyncf();
  }, [category]);

  return (
    <FormControl>
      <SimpleGrid columns={1} gap={4}>
        <GeneralInput
          value={site}
          useSet={setWebsite}
          placeholder={"https://Your-Website-Url"}
        />
        <SizeIncreaseHover>
          <Select
            value={category}
            bg={colours.text.primary}
            onChange={(e) => setcategory(e.target.value)}
          >
            <option value={undefined}>Please Select a Main Category</option>
            {fetchedMainCategories.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </SizeIncreaseHover>
        <SizeIncreaseHover>
          <Select
            value={subCategory}
            bg={colours.text.primary}
            onChange={(e) => setSubCategory(e.target.value)}
          >
            <option value={undefined}>Please Select a Sub Category</option>
            {fetchedSubCategories.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </SizeIncreaseHover>
        <Button
          onClick={async () => {
            setIsLoading(true); // Set isLoading to true before making the request
            await requestSiteAdd(
              setIsLoading,
              {
                site,
                category,
                subcategory: subCategory,
                sub_subcategory: subSubCategory,
              },
              siteId
            );
            setIsLoading(false); // Set isLoading back to false after the request
            setSubSubCategory("");
            setSubCategory("");
            setcategory("");
            setWebsite("");
          }}
          isLoading={isLoading}
          loadingText="Submitting"
          w="fit-content"
          bg={colours.action.primary}
          colorScheme="orange"
        >
          {copy.submit}
        </Button>
      </SimpleGrid>
    </FormControl>
  );
}
