import {
  Flex,
  Text,
  Image,
  Grid,
  GridItem,
  Card,
  CardBody,
  CardHeader,
} from "@chakra-ui/react";

import { MainPage } from "../../components";
import { copy as C } from "../../configs/copy";
import Logo from "../../UI/Logo";

import { useAppStore } from "./../../stores";

// Create an array of feature objects

const AboutPage = () => {
  const { isMobile } = useAppStore((state) => state);
  const copy = C[useAppStore((state) => state.language)].aboutPage;
  const features = copy.features;

  return (
    <MainPage top="-100px" title="About Us" justify="center" desktopWidth="85%" labelText={'Learn more about us, what we do, and why we do it!'}>
      <CardBody>
        <Flex direction="column" align="center">
          <CardHeader>
            <Flex>
              <Logo mr={"auto"} />
            </Flex>
            <Text mb="4">{copy.description}</Text>
          </CardHeader>
          <Grid
            templateColumns={`repeat(${isMobile ? 1 : 2}, minmax(250px, 1fr))`}
            gap={4}
          >
            {features.map((feature, index) => (
              <GridItem key={index}>
                <Card h="100%">
                  <CardHeader>
                    <Text fontWeight="bold">{feature.title}</Text>
                    <Text>{feature.description}</Text>
                  </CardHeader>
                  <CardBody>
                    <Image src={feature.imageUrl} alt={feature.title} mb="4" />
                  </CardBody>
                </Card>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </CardBody>
    </MainPage>
  );
};

export default AboutPage;
