import { create } from "zustand";

import { supabase, useUserStore, useAppStore } from "./index";
import { choose } from "../functions/utils";

const useSiteStore = create((set, get) => ({
  sites: [],
  current: { site: "a", id: null, user_id: null, comments: [] },
  categories: [],
  isLoading: false,

  fetchCategorySites: async (categories) => {
    const toast = useAppStore.getState().usefulToast;
    try {
      set({ isLoading: true });

      let data = [];
      if (!categories || categories.length == 0) {
        const { data: nonAuthSites } = await supabase
          .from("websites")
          .select("*")
          .eq("status", "approved");

        nonAuthSites.forEach((e) => data.push(e));
      } else {
        console.log("[User IS AUTH] - Pulling Categories");
        const intCats = categories.map((e) => e);

        const { data: categorySites } = await supabase
          .from("websites")
          .select("*")
          .eq("status", "approved")
          .in("category", intCats);
        const { data: subCategorySites } = await supabase
          .from("websites")
          .select("*")
          .eq("status", "approved")
          .in("subcategory", intCats);
        const { data: subSubCategorySites } = await supabase
          .from("websites")
          .select("*")
          .eq("status", "approved")
          .in("sub_subcategory", intCats);

        data = [...categorySites, ...subCategorySites, ...subSubCategorySites];
      }

      console.log("[sites len] - ", data.length);
      const randomSite = choose(data);
      randomSite.comments = await useSiteStore
        .getState()
        .getComments(randomSite.id);
      set({
        sites: data,
        current: { ...randomSite } || { site: "https://example.com", id: 1 },
      });
      set({ isLoading: false });
      return;
    } catch (error) {
      console.error("Error fetching category sites:", error);
      toast({
        title: "Error",
        description:
          error.message + "err ls 59" || "error fetching category sites",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    set({ isLoading: false });
  },

  setCurrent: async () => {
    set({ isLoading: true });
    const allSites = get().sites;
    let currentSite = "";

    let count = 0;
    while (true) {
      if (count > 10) {
        currentSite = allSites[Math.floor(Math.random() * allSites.length)];
        break;
      }

      currentSite = allSites[Math.floor(Math.random() * allSites.length)];
      if (currentSite?.likes === 0 && currentSite?.dislikes > 0) {
        count += 1;
        continue;
      } else {
        break;
      }
    }

    const navigateTo = useAppStore.getState().navigateTo;
    navigateTo(`discover/${currentSite.id}`);
    const comments = await get().getComments(currentSite.id);
    currentSite.comments = comments;

    set({ current: currentSite });
    set({ isLoading: false });
  },

  setCurrentFixed: (current) => {
    set({ current: current });
  },
  like: async () => {
    // make the function check if the user is logged in using isLoggedIn from user store
    if (!useUserStore.getState().isLoggedIn()) {
      console.log("User cant like site");
      return;
    }

    // make the site update the like count on the server
    set({ loading: true });
    const user_id = useUserStore.getState().getID();
    const site_id = get().current.id;
    try {
      const { error } = await supabase.rpc("like_website", {
        user_id,
        site_id,
      });

      if (error) {
        console.log("Error liking site:", error); // TODO: Add better error handeling
      }
    } catch (e) {
      console.log("Error liking site: ", e); // TODO: Add better error handeling
    }
    set({ loading: false });
  },

  dislike: async () => {
    // make the function check if the user is logged in using isLoggedIn from user store
    if (!useUserStore.getState().isLoggedIn()) {
      console.log("User cant like site");
      return;
    }

    set({ loading: true });
    // make the site update the like count on the server
    const user_id = useUserStore.getState().getID();
    const site_id = get().current.id;
    try {
      const { data, error } = await supabase.rpc("dislike_website", {
        user_id,
        site_id,
      });

      if (error) {
        console.log("Error disliking site:", error); // TODO: Add better error handeling
      }
    } catch (e) {
      console.log("Error disliking site: ", e); // TODO: Add better error handeling
    }
    set({ loading: false });
  },

  requestSiteAdd: async (setIsLoading, data, siteId) => {
    const isAdmin = useUserStore.getState().isAdmin;
    const isLoggedIn = useUserStore.getState().isLoggedIn;
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;
    const extraUsefulToast = useAppStore.getState().extraUsefulToast;

    try {
      if (!isLoggedIn()) throw new Error("Cannot Add Site if Logged Out!");

      setIsLoading(true);
      console.log("requestSiteAdd: ", data);
      const dataObj = {
        ...data,
        user_id: useUserStore.getState().getID(),
        status: isAdmin() ? "approved" : "pending",
      };
      console.log("requestSiteAdd: ", siteId);
      if (siteId) dataObj.id = siteId;

      const { data: site, error } = await supabase
        .from("websites")
        .upsert(dataObj)
        .select();

      if (error) throw new Error("Error adding site: ", error);
      usefulToast({
        title: "Site Added!",
        description: "Your site has been added to the list!",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom-middle",
      });
    } catch (e) {
      usefulToast({
        title: "Error Adding Site!",
        description: e.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  },

  addComment: async (comment, setShowComments, setIsLoading) => {
    const isLoggedIn = useUserStore.getState().isLoggedIn();
    const toast = useAppStore.getState().toast;
    const usefulToast = useAppStore.getState().usefulToast;

    try {
      if (!isLoggedIn) throw new Error("Cannot add comment if logged out!");

      setIsLoading(true);
      const { data, error } = await supabase
        .from("comments")
        .insert({
          user_id: useUserStore.getState().getID(),
          site_id: get().current.id,
          content: comment,
        })
        .select();

      if (error) throw new Error("Error adding comment: ", error);
      toast({
        title: "Comment Added!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      set({
        current: {
          ...get().current,
          comments: [
            ...(get().current?.comments || []),
            { ...data[0], user: useUserStore.getState().profile },
          ],
        },
      });
    } catch (e) {
      if (!isLoggedIn) {
        useAppStore.getState().authModal.onOpen();
        usefulToast({
          title: "Error Adding Comment!",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      toast({
        title: "Error Adding Comment!",
        description: e.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setShowComments(false);
    }
  },

  getComments: async (siteID) => {
    let { data, error } = await supabase
      .from("comments")
      .select("*")
      .eq("site_id", siteID);

    if (error) {
      console.error("Error fetching comments:", error);
      data = [];
    } else {
      console.log("Fetched comments:", data);
    }

    let { data: userNames, error: userNamesError } = await supabase
      .from("profiles")
      .select("*")
      .in(
        "id",
        data.map((comment) => comment.user_id)
      );

    if (userNamesError) {
      console.error("Error fetching usernames:", userNamesError);
      userNames = [];
    } else {
      console.log("Fetched usernames:", userNames);
      data.map((comment) => {
        comment.user = userNames.find((user) => user.id === comment.user_id);
      });
    }
    if (error) return [];
    return data;
  },

  getAllUsedCategories: async (setIsLoading) => {
    // Get all the used categories

    let usedCategories = [];
    const toast = useAppStore.getState().usefulToast;
    setIsLoading(true);
    try {
      const { data, error } = await supabase.from("distinct_category").select();
      const { data: data1, error: error1 } = await supabase
        .from("distinct_subcategory")
        .select();
      const { data: data2, error: error2 } = await supabase
        .from("distinct_subsubcategory")
        .select();

      if (error || error1 || error2) {
        throw new Error("Some Error occured whilst fetching unique categories");
      }

      console.log("[USED CATEGORIES BEFORE]");
      console.log(data, data1, data2);
      usedCategories = [
        ...data.map((e) => e.category),
        ...data1.map((e) => e.subcategory),
        ...data2.map((e) => e.sub_subcategory),
      ];
      console.log("[USED CATEGORIES LN321] - ", usedCategories);
    } catch (e) {
      usefulToast({
        title: e || "Some error occured whilst fetching the unique categories",
        status: "error",
        isClosable: true,
        duration: 9000,
      });
      console.log(e);
    } finally {
      setIsLoading(false);
      return usedCategories;
    }
  },
  getParamSite: async (id) => {
    const { toast, usefulToast } = useAppStore.getState();
    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from("websites")
        .select("*")
        .eq("status", "approved")
        .eq("id", id);
      const comments = await get().getComments(id);

      if (error || data.length === 0) {
        set({ current: { site: "a", id: 1 } });
        throw new Error("Cannot Fetch That unique site");
      }

      const site = data[0];
      // site.title ? (document.title = site.title) : (document.title = site.site);
      const metaDescription = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescription) {
        metaDescription.setAttribute("content", site.description);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        newMeta.setAttribute("content", site.description);
        document.head.appendChild(newMeta);
      }

      set({ current: { ...site, comments: comments } });
      return site;
    } catch (e) {
      usefulToast({
        title: e || "Some error occured whilst trying to get this site",
        duration: 9000,
        isClosable: true,
      });
      console.error(e);
    } finally {
      set({ isLoading: false });
    }
  },
  totalSitesThusFar: async () => {
    const { data, error } = await supabase
      .from("sites_count")
      .select("total_count");
    if (error) {
      console.error("Error fetching total added this week:", error);
      return 0;
    }
    return data[0].total_count;
  },
}));

export default useSiteStore;
