import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppStore, useUserStore } from "../../stores";

export default function Refferal() {
  const { id } = useParams();
  const {
    authModal: { onOpen },
    navigateTo,
  } = useAppStore((state) => state);
  const { isLoggedIn, setReferral, setReferralID, setAuthView } = useUserStore(
    (state) => state
  );

  useEffect(() => {
    if (isLoggedIn()) {
      navigateTo("discover");
    }

    if (id) {
      // Refactor. 1. Set the referral code into local storage. 2. Redirect the user to the homepage. 3. The user can create an account whenever.
      setReferral(true);
      localStorage.setItem
      setReferralID(id);
      navigateTo("");
    }
  }, []);

  // useEffect(() => {
  //   if (!isLoggedIn()) {
  //     onOpen();
  //   }
  // }, [onOpen]);
  return <></>;
}
