import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Box,
  Flex,
  Heading,
  Image,
  Text,
  SimpleGrid,
  Grid,
  useBreakpointValue,
} from "@chakra-ui/react";

import { MainPage } from "../../components";
import { CategoryItem } from "../CategoriesExploration/CategoriesExploration";

import {
  useCategoriesStore,
  useAppStore,
  useSiteStore,
  useUserStore,
} from "../../stores";
import Leaderboard from "../LeaderBoard/Leaderboard";

export default function CategoryItemExploration({ setIsCategoryDiscoverPage }) {
  const { navigateTo } = useAppStore((state) => state);
  const { convertNameToID, gettop5Sites, getAllSubFromMainID } =
    useCategoriesStore((state) => state);
  const [top6Sites, settop6Sites] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [toggledCategories] = useState(
    useUserStore((state) => state?.profile?.categories || []).map((e) =>
      Number(e)
    )
  );
  const { current, setCurrentFixed } = useSiteStore((state) => state);
  const [mainCategory, setMainCategory] = useState({ name: "id", id: "" });
  const { updateCategories } = useUserStore((state) => state);
  const { id } = useParams();
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
    lg: false,
    xl: false,
  });
  useEffect(() => {
    console.log(id);
    console.log(!isNaN(Number(id)));
    if (!isNaN(Number(id)) && window.location.pathname.includes("discover")) {
      navigateTo(`discover/${id}`);
      setIsCategoryDiscoverPage(false);
    }

    const asyncf = async () => {
      // Convert the category name to the ID alternative
      const categoryID = await convertNameToID(id);
      // If there isn't a category
      if (!categoryID) {
      }

      setMainCategory({ name: id, id: categoryID });

      console.log("categoryid", categoryID);
      // Collect the following information
      // - Top 5 sites in that category
      const top6Sites = await gettop5Sites(categoryID);
      settop6Sites(top6Sites);
      // - All the subcategories
      const subCategories = await getAllSubFromMainID(categoryID, true);
      const alphabetizedSubCategories = subCategories.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      setSubCategories(alphabetizedSubCategories);
      // - Placeholders for some arbitrage stats (Will figure out later)
    };

    asyncf();
  }, [
    id,
    convertNameToID,
    gettop5Sites,
    getAllSubFromMainID,
    navigateTo,
    current,
  ]);

  const toggleCategory = async (category) => {
    // We want to rewrok this to directly add it to the profile & then just head to discover page.
    // This will make it super easy for new users to use the app.
    // WOOHOO!
    if (toggledCategories.includes(category)) {
      await updateCategories(
        toggledCategories.filter((e) => e !== category),
        () => {}
      );
    } else {
      await updateCategories([...toggledCategories, category], () => {});
    }
  };

  return (
    <Flex flexDir="column">
      <MainPage
        top="-40"
        title={id}
        desktopWidth={"50%"}
        labelText="These are all subcategories, if you have an account it will save it to your profile."
        sideExtension={<></>}
      >
        <SimpleGrid rows={2} gap="20">
          {/* 1st Column */}
          <Flex flexDir={"column"} gap="4">
            <Heading fontSize="2xl">Choose a category to browse</Heading>
            <SimpleGrid
              minChildWidth={"190px"}
              alignContent={"center"}
              w="100%"
              align={"center"}
              justify={"center"}
              justifyItems={"center"}
              columns={4}
              justifyContent="flex-start"
              gap="2"
            >
              {/* Render subcategories and Save button here */}
              <Box minW={"190px"} flexGrow={"1"}>
                <CategoryItem
                  category={mainCategory}
                  onClick={() => toggleCategory(mainCategory.id)}
                  key={mainCategory.name}
                  toggledCategories={toggledCategories}
                />
              </Box>

              {subCategories.map((e) => (
                <Box w={"190px"}>
                  <CategoryItem
                    category={e}
                    onClick={() => toggleCategory(e.id)}
                    key={e.name}
                    toggledCategories={toggledCategories}
                  />
                </Box>
              ))}
              <Box w={"190px"} />
              <Box w={"190px"} />
              <Box w={"190px"} />
              <Box w={"190px"} />
              <Box w={"190px"} />
            </SimpleGrid>
          </Flex>
        </SimpleGrid>
        <Box w="100%" bg="black" h="2px" rounded="full" mt="2" mb="2"></Box>
        {/* 2nd Column */}
        <Box>
          <Heading fontSize="2xl" mb="4">
            Most popular in this category
          </Heading>

          {/* Render top 6 sites with smaller thumbnails */}
          <Grid templateColumns={isMobile ? "1fr" : "repeat(4, 1fr)"} gap="6">
            {top6Sites.map((e, index) => (
              <Card
                onClick={() => {
                  console.log("hello");
                  setCurrentFixed(e);
                  navigateTo("discover/" + e.id);
                }}
                rounded="md"
                overflow={"hidden"}
                _hover={{ cursor: "pointer" }}
                key={e.site}
                w="100%"
              >
                {/* Reduce thumbnail size */}
                <CardHeader p="0">
                  <Image
                    src={
                      e?.snapshot_url ||
                      "https://placehold.co/400x267?text=Visit%20this%20website&font=roboto"
                    } // Smaller size
                    objectFit={"cover"}
                    w="100%"
                    fallbackSrc={
                      "https://placehold.co/400x267?text=Visit%20this%20website&font=roboto"
                    }
                    aspectRatio={"1 / 1"}
                    alt={e.title}
                  />
                </CardHeader>
                <CardBody>
                  <Flex
                    key={e.site}
                    w="100%"
                    mt="auto"
                    flexDir={"column"}
                    justify={"end"}
                    alignSelf="flex-end"
                    fontSize="xs"
                  >
                    {`${e.site
                      .replace("https://", "")
                      .replace("http://", "")
                      .replace("www.", "")
                      .split("/")

                      .shift()}`}
                    <Flex align="center" gap="2" textAlign={"right"}></Flex>
                  </Flex>
                </CardBody>
              </Card>
            ))}
          </Grid>
        </Box>
        <Box ml="auto" mt="5">
          <Link to="/contact?reason=sponsorship">
            <Text color="gray.400" w="100%" textAlign={"end"}>
              Sponsor this category
            </Text>
          </Link>
        </Box>
      </MainPage>
    </Flex>
  );
}
