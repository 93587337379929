import likeGif from "../assets/like.gif";
import editProfile from "../assets/editProfile.gif";
import cookieStore from "../assets/cookieStore.gif";
import categoriesShowcase from "../assets/categories.gif";
import multiLang from "../assets/multiLanguage.gif";
import getCookieCoins from "../assets/getCookieCoins.gif";
import addSiteShowcase from "../assets/addSite.gif";

/* 
This Config file contains the copy for the entire app.
For example Every single string that is more than 2 words long is stored here.
This is so that the app can be easily translated into other languages. In the future ( They will be stored here in this file, and just labeled with their language Code for the language
For example:
{
    ENG: {
        Tagline: "Hello, World!"
    },
    ESP: {
        Tagline: "Hola, Mundo!"
    },
    ...
}
This will only start once we got the mvp working completely, And actually need to start caring about translations.
Thanks SIMON
*/

export const copy = {
  ENG: {
    addSite: {
      site: "Site",
      mainCategory: "Main Category",
      select: "Select",
      subCategory: "Sub Category",
      subSubCategory: "Sub-Sub-Category",
      submit: "Submit",
    },
    adminCard: {
      adminCard: "Admin Card",
      goToAdminPanel: "Go to Admin Panel",
    },
    emailCaptureForm: {
      thereWasAnError: "There was an error!",
      email: "Email",
      save: "Save",
    },
    emailUs: {
      toastEmailSent: "Email Sent!",
      toastEmailSentDescription: "Your email has been sent successfully!",
      toastEmailFailed: "Email Failed!",
      toastEmailFailedDescription: "Your email has failed to send!",
      nameInputPlaceholder: "Name",
      emailInputPlaceholder: "Email",
      emailBodyInputPlaceholder: "Send us some Cookie Dough!",
      sendEmail: "Send Email",
    },
    footer: {
      privacyPolicy: "Privacy Policy",
    },
    navBarHamburgerMenu: {
      discover: "Discover",
      categories: "Categories",
      profile: "Profile",
      register: "Register",
      leaderboard: "Leaderboard",
      addSite: "Add Site",
      contactUs: "Contact Us",
      about: "About",
      login: "Login",
      logout: "Logout",
      language: "Language",
    },
    websiteWindow: {
      useAllCategories: "Use All Categories",
      selectCategories: "Select Categories",
    },
    landingPage: {
      thanksForJoining:
        "Hey thx! We will be sure to send you an email when we launch.",
      tagLine: "Discover New Websites By Browsing Your Favorite Categories.",
      getStarted: "Get Started",
    },
    addSitePage: {
      addAWebsite: "Add a Website",
    },
    adminPage: {
      confirmOrDenySites: "Confirm/Deny Sites",
      addASite: "Add Site",
    },
    categoriesPage: {
      selectACategory: "Select A Category",
      modifyFavouriteCategories: "Choose Your Categories",
      modifyFavouriteCategoriesSub: "Select categories and start exploring new websites.",
      confirmFavourites: "Confirm Favorites",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "Confirm Favorites",
      confirmationContext:
        "Are you sure you want to continue without selecting any categories? &lt;br&gt;  You will be shown All the websites in the database. &lt;br&gt; Dont worry you can change this later. &lt;br&gt; ",
      confirm: "Confirm",
    },
    leaderboardPage: {
      userLiked: "Most Active Users",
      websiteLikes: "Most popular websites",
      userCookiePoints: "Most CookiePoints",
    },
    profilePage: {
      liked: "👍Liked",
      created: "Created",
      coins: "🍪 POINTS",
      save: "Save",
      copiedToClipboard: "Copied to Clipboard!",
      empty: "Empty",
      placeholderLocation: "Cookie Land",
      placeholderTagline:
        "CookieLander | Person Loving Sleep | Liking Websites | CookieMonster",
      referralText: "Earn 100 🍪coins with every referral!",
      referralCopy:
        "Share this link to earn 100 🍪 POINTS with every referral!",
      favouriteCategories: "Favorite categories",
      sitesIHaveLiked: "Sites I have liked",
    },
    aboutPage: {
      title: "About Us",
      description:
        "Welcome to cookiejar! We're here to provide you with a delightful browsing experience.",
      features: [
        {
          title: "Liking Feature",
          description:
            "Like or dislike sites to curate your personalized cookie experience.",
          imageUrl: likeGif,
        },
        {
          title: "Profile Editing",
          description: "Edit your profile to make it uniquely yours.",
          imageUrl: editProfile,
        },
        {
          title: "Cookie Store",
          description:
            "Coming Soon! Buy power-ups and more from our cookie store.",
          imageUrl: cookieStore,
        },
        {
          title: "Swapping Categories",
          description:
            "Easily swap categories and enjoy tailored pages based on your preferences.",
          imageUrl: categoriesShowcase,
        },
        {
          title: "International App",
          description:
            "Experience cookiejar in multiple languages, including Chinese, Español, English, French, German, Italian, and more.",
          imageUrl: multiLang,
        },
        {
          title: "Steps to Get Cookie Coins",
          description:
            "Earn cookie coins through referrals:\n1. Share your referral URL.\n2. Receive likes from others.\n3. Watch your cookie coins grow!",
          imageUrl: getCookieCoins,
        },
        {
          title: "Ability to Submit Sites",
          description:
            "Submit your favorite cookie sites and contribute to our community.",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro:
        "Your privacy is important to us. This Privacy Policy explains how we collect, use, and disclose your information.",
      dataCollection:
        "We collect certain information when you visit and use our social media app. This includes information you provide directly, such as your name, profile picture, email address, and any content you share or interact with on the app. We also collect information about your interactions with other users, such as likes, comments, and follows.",
      dataUsage:
        "The information we collect may be used to personalize your experience on the app and improve our services. We use this data to tailor your content feed, recommend relevant connections, and enhance your overall app experience. We may also use the information to analyze trends, troubleshoot issues, and conduct research to improve our features and functionality.",
      cookies:
        "We use cookies and similar tracking technologies to analyze website traffic and improve user experience on the app. These technologies allow us to remember your preferences, understand how you use the app, and provide you with a more customized experience. You can manage your cookie preferences through your browser settings.",
      authentication:
        "To access certain features, you can create an account using your email and password. This information is securely stored and used to authenticate your identity when you log in. You can also choose to log in using third-party authentication services, in which case we receive limited information from those services to create and manage your account.",
      imageStorage:
        "You can upload images to your profile. These images are stored securely in our database and associated with your account. You have control over the visibility of your images based on your privacy settings. We do not use your images for advertising purposes or share them with third parties without your consent.",
      emailUsage:
        "We may use your email to send you important updates and notifications related to your account and app usage. These communications may include account-related information, announcements, and promotional materials. You can manage your communication preferences in your account settings.",
      thirdPartySharing:
        "We do not share your personal information with third parties without your explicit consent. However, if you choose to connect your account with third-party services (such as sharing content on other social media platforms), certain information may be shared with those services according to your preferences.",
      userContent:
        "Your user-generated content may be visible to other users of the app, subject to your privacy settings. This includes posts, comments, profile information, and any other content you choose to share. Please be mindful of the information you share, as it may become publicly accessible.",
    },
  },
  ESP: {
    addSite: {
      site: "Sitio",
      mainCategory: "Categoría Principal",
      select: "Seleccionar",
      subCategory: "Sub Categoría",
      subSubCategory: "Sub-Sub Categoría",
      submit: "Enviar",
    },
    adminCard: {
      adminCard: "Tarjeta de Administrador",
      goToAdminPanel: "Ir al Panel de Administración",
    },
    emailCaptureForm: {
      thereWasAnError: "Hubo un error",
      email: "Correo Electrónico",
      save: "Guardar",
    },
    emailUs: {
      toastEmailSent: "Email Enviado",
      toastEmailSentDescription: "Tu email ha sido enviado exitosamente",
      toastEmailFailed: "Email Fallido",
      toastEmailFailedDescription: "Tu email no se pudo enviar",
      nameInputPlaceholder: "Nombre",
      emailInputPlaceholder: "Correo Electrónico",
      emailBodyInputPlaceholder: "Envíanos un poco de Masa de Galleta",
      sendEmail: "Enviar Email",
    },
    footer: {
      privacyPolicy: "Política de Privacidad",
    },
    navBarHamburgerMenu: {
      discover: "Descubrir",
      categories: "Categorías",
      profile: "Perfil",
      register: "Registrarse",
      leaderboard: "Tabla de Posiciones",
      addSite: "Agregar Sitio",
      contactUs: "Contáctanos",
      about: "Acerca de",
      login: "Iniciar Sesión",
      logout: "Cerrar Sesión",
      language: "Idioma",
    },
    websiteWindow: {
      useAllCategories: "Usar Todas las Categorías",
      selectCategories: "Seleccionar Categorías",
    },
    landingPage: {
      thanksForJoining:
        "¡Gracias por Registrarte! Te enviaremos un email primero cuando lancemos.",
      tagLine: "¿Listo para Redescubrir la Web?",
      getStarted: "Comenzar",
    },
    addSitePage: {
      addAWebsite: "Agregar un Sitio Web",
    },
    adminPage: {
      confirmOrDenySites: "Confirmar / Denegar Sitios",
      addASite: "Agregar Sitio",
    },
    categoriesPage: {
      selectACategory: "Seleccionar una Categoría",
      modifyFavouriteCategories: "Modificar Categorías Favoritas",
      confirmFavourites: "Confirmar Favoritos",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "Confirmar Favoritos",
      confirmationContext:
        "¿Estás seguro de continuar sin seleccionar ninguna categoría? Se te mostrarán todos los sitios web en la base de datos. No te preocupes, puedes cambiar esto después.",
      confirm: "Confirmar",
    },
    leaderboardPage: {
      userLiked: "Usuario Le gustó",
      websiteLikes: "Me gusta del Sitio Web",
      userCookiePoints: "Puntos de Galleta del Usuario",
    },
    profilePage: {
      liked: "👍 Le gustó",
      created: "Creado",
      coins: "🍪 Monedas",
      save: "Guardar",
      copiedToClipboard: "Copiado al Portapapeles",
      empty: "Vacío",
      placeholderLocation: "Tierra de Galletas",
      placeholderTagline:
        "CookieLander | Amante del Sueño | Gusto por los Sitios Web | Monstruo de las Galletas",
      referralText: "¡Gana 100 🍪 monedas con cada referencia!",
      referralCopy:
        "¡Comparte este enlace con tus amigos para ganar 100 🍪 monedas por cada referencia y no lo pierdas!",
      favouriteCategories: "Categorías Favoritas",
      sitesIHaveLiked: "Sitios que Me Gustaron",
    },
    aboutPage: {
      title: "Acerca de Nosotros",
      description:
        "¡Bienvenido a cookiejar! Estamos aquí para brindarte una experiencia de navegación encantadora.",
      features: [
        {
          title: "Función de Me Gusta",
          description:
            "Dale Me gusta o no a los sitios para curar tu experiencia personalizada de galletas.",
          imageUrl: likeGif,
        },
        {
          title: "Edición de Perfil",
          description: "Edita tu perfil para hacerlo único.",
          imageUrl: editProfile,
        },
        {
          title: "Tienda de Galletas",
          description:
            "¡Próximamente! Compra power-ups y más en nuestra tienda de galletas.",
          imageUrl: cookieStore,
        },
        {
          title: "Intercambio de Categorías",
          description:
            "Cambia fácilmente las categorías y disfruta de páginas adaptadas según tus preferencias.",
          imageUrl: categoriesShowcase,
        },
        {
          title: "Aplicación Internacional",
          description:
            "Experimenta cookiejar en varios idiomas, incluyendo chino, español, inglés, francés, alemán, italiano y más.",
          imageUrl: multiLang,
        },
        {
          title: "Pasos para Obtener Monedas de Galleta",
          description:
            "Gana monedas de galleta a través de referencias:\n1. Comparte tu URL de referencia.\n2. Recibe likes de otros.\n3. ¡Observa cómo crecen tus monedas de galleta!",
          imageUrl: getCookieCoins,
        },
        {
          title: "Capacidad para Enviar Sitios",
          description:
            "Envía tus sitios de galletas favoritos y contribuye a nuestra comunidad.",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro:
        "Tu privacidad es importante para nosotros. Esta Política de Privacidad explica cómo recopilamos, usamos y divulgamos tu información.",
      dataCollection:
        "Recopilamos cierta información cuando visitas y utilizas nuestra aplicación de redes sociales. Esto incluye información que proporcionas directamente, como tu nombre, foto de perfil, dirección de correo electrónico y cualquier contenido que compartas o con el que interactúes en la aplicación. También recopilamos información sobre tus interacciones con otros usuarios, como me gusta, comentarios y seguidores.",
      dataUsage:
        "La información que recopilamos puede utilizarse para personalizar tu experiencia en la aplicación y mejorar nuestros servicios. Utilizamos estos datos para adaptar tu flujo de contenido, recomendar conexiones relevantes y mejorar tu experiencia general en la aplicación. También podemos utilizar la información para analizar tendencias, solucionar problemas y llevar a cabo investigaciones para mejorar nuestras funciones y funcionalidades.",
      cookies:
        "Utilizamos cookies y tecnologías de seguimiento similares para analizar el tráfico del sitio web y mejorar la experiencia del usuario en la aplicación. Estas tecnologías nos permiten recordar tus preferencias, comprender cómo utilizas la aplicación y proporcionarte una experiencia más personalizada. Puedes gestionar tus preferencias de cookies a través de la configuración de tu navegador.",
      authentication:
        "Para acceder a ciertas funciones, puedes crear una cuenta utilizando tu dirección de correo electrónico y contraseña. Esta información se almacena de manera segura y se utiliza para autenticar tu identidad cuando inicias sesión. También puedes optar por iniciar sesión utilizando servicios de autenticación de terceros, en cuyo caso recibimos información limitada de esos servicios para crear y gestionar tu cuenta.",
      imageStorage:
        "Puedes cargar imágenes en tu perfil. Estas imágenes se almacenan de manera segura en nuestra base de datos y se asocian a tu cuenta. Tienes control sobre la visibilidad de tus imágenes según tus preferencias de privacidad. No utilizamos tus imágenes con fines publicitarios ni las compartimos con terceros sin tu consentimiento.",
      emailUsage:
        "Podemos utilizar tu dirección de correo electrónico para enviarte actualizaciones importantes y notificaciones relacionadas con tu cuenta y el uso de la aplicación. Estas comunicaciones pueden incluir información relacionada con la cuenta, anuncios y materiales promocionales. Puedes gestionar tus preferencias de comunicación en la configuración de tu cuenta.",
      thirdPartySharing:
        "No compartimos tu información personal con terceros sin tu consentimiento explícito. Sin embargo, si eliges conectar tu cuenta con servicios de terceros (como compartir contenido en otras plataformas de redes sociales), es posible que se comparta cierta información con esos servicios según tus preferencias.",
      userContent:
        "El contenido generado por el usuario que crees puede ser visible para otros usuarios de la aplicación, sujeto a tu configuración de privacidad. Esto incluye publicaciones, comentarios, información de perfil y cualquier otro contenido que elijas compartir. Ten en cuenta la información que compartes, ya que puede ser accesible públicamente.",
      // Agrega más pares clave-valor para otras secciones y traducciones
    },
  },
  FRE: {
    addSite: {
      site: "Site",
      mainCategory: "Catégorie Principale",
      select: "Sélectionner",
      subCategory: "Sous Catégorie",
      subSubCategory: "Sous-Sous Catégorie",
      submit: "Soumettre",
    },
    adminCard: {
      adminCard: "Carte d'Administrateur",
      goToAdminPanel: "Aller au Panel d'Administration",
    },
    emailCaptureForm: {
      thereWasAnError: "Une erreur est survenue",
      email: "Adresse E-mail",
      save: "Enregistrer",
    },
    emailUs: {
      toastEmailSent: "E-mail Envoyé",
      toastEmailSentDescription: "Votre e-mail a été envoyé avec succès",
      toastEmailFailed: "Échec de l'E-mail",
      toastEmailFailedDescription: "Votre e-mail n'a pas pu être envoyé",
      nameInputPlaceholder: "Nom",
      emailInputPlaceholder: "Adresse E-mail",
      emailBodyInputPlaceholder: "Envoyez-nous un peu de Pâte à Cookie",
      sendEmail: "Envoyer l'E-mail",
    },
    footer: {
      privacyPolicy: "Politique de Confidentialité",
    },
    navBarHamburgerMenu: {
      discover: "Découvrir",
      categories: "Catégories",
      profile: "Profil",
      register: "S'inscrire",
      leaderboard: "Classement",
      addSite: "Ajouter un Site",
      contactUs: "Contactez-nous",
      about: "À Propos",
      login: "Se Connecter",
      logout: "Se Déconnecter",
      language: "Langue",
    },
    websiteWindow: {
      useAllCategories: "Utiliser Toutes les Catégories",
      selectCategories: "Sélectionner les Catégories",
    },
    landingPage: {
      thanksForJoining:
        "Merci de vous être inscrit. Nous vous enverrons un e-mail en premier lorsque nous lançons.",
      tagLine: "Prêt à Redécouvrir le Web ?",
      getStarted: "Commencer",
    },
    addSitePage: {
      addAWebsite: "Ajouter un Site Web",
    },
    adminPage: {
      confirmOrDenySites: "Confirmer / Refuser les Sites",
      addASite: "Ajouter un Site",
    },
    categoriesPage: {
      selectACategory: "Sélectionner une Catégorie",
      modifyFavouriteCategories: "Modifier les Catégories Préférées",
      confirmFavourites: "Confirmer les Favoris",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "Confirmer les Favoris",
      confirmationContext:
        "Êtes-vous sûr de continuer sans sélectionner de catégorie ? Vous verrez tous les sites web dans la base de données. Ne vous inquiétez pas, vous pourrez changer cela par la suite.",
      confirm: "Confirmer",
    },
    leaderboardPage: {
      userLiked: "Aimé par l'Utilisateur",
      websiteLikes: "Likes du Site Web",
      userCookiePoints: "Points Cookie de l'Utilisateur",
    },
    profilePage: {
      liked: "👍 Aimé",
      created: "Créé",
      coins: "🍪 Pièces",
      save: "Enregistrer",
      copiedToClipboard: "Copié dans le Presse-papiers",
      empty: "Vide",
      placeholderLocation: "Terre des Cookies",
      placeholderTagline:
        "CookieLander | Amateur de Sommeil | Aime les Sites Web | Monstre des Cookies",
      referralText: "Gagnez 100 🍪 pièces à chaque recommandation !",
      referralCopy:
        "Partagez ce lien avec vos amis pour gagner 100 🍪 pièces à chaque recommandation et ne le perdez pas !",
      favouriteCategories: "Catégories Préférées",
      sitesIHaveLiked: "Sites que J'ai Aimés",
    },
    aboutPage: {
      title: "À Propos de Nous",
      description:
        "Bienvenue sur cookiejar ! Nous sommes là pour vous offrir une expérience de navigation enchantée.",
      features: [
        {
          title: "Fonction J'aime",
          description:
            "Aimez ou n'aimez pas les sites pour personnaliser votre expérience de biscuit.",
          imageUrl: likeGif,
        },
        {
          title: "Modification de Profil",
          description: "Modifiez votre profil pour le rendre unique.",
          imageUrl: editProfile,
        },
        {
          title: "Boutique de Biscuits",
          description:
            "Bientôt disponible ! Achetez des améliorations et plus encore dans notre boutique de biscuits.",
          imageUrl: cookieStore,
        },
        {
          title: "Échange de Catégories",
          description:
            "Échangez facilement les catégories et profitez de pages personnalisées selon vos préférences.",
          imageUrl: categoriesShowcase,
        },
        {
          title: "Application Internationale",
          description:
            "Découvrez cookiejar dans plusieurs langues, dont le chinois, l'espagnol, l'anglais, le français, l'allemand, l'italien, et bien plus encore.",
          imageUrl: multiLang,
        },
        {
          title: "Étapes pour Obtenir des Pièces de Biscuit",
          description:
            "Gagnez des pièces de biscuit grâce aux recommandations :\n1. Partagez votre URL de recommandation.\n2. Recevez des likes des autres.\n3. Regardez vos pièces de biscuit augmenter !",
          imageUrl: getCookieCoins,
        },
        {
          title: "Capacité à Soumettre des Sites",
          description:
            "Soumettez vos sites de biscuit préférés et contribuez à notre communauté.",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro:
        "Votre vie privée est importante pour nous. Cette politique de confidentialité explique comment nous recueillons, utilisons et divulguons vos informations.",
      dataCollection:
        "Nous recueillons certaines informations lorsque vous visitez et utilisez notre application de médias sociaux...",
      dataUsage:
        "Les informations que nous recueillons peuvent être utilisées pour personnaliser votre expérience...",
      cookies:
        "Nous utilisons des cookies et des technologies de suivi similaires pour analyser le trafic du site Web...",
      authentication:
        "Pour accéder à certaines fonctionnalités, vous pouvez créer un compte en utilisant votre adresse e-mail et votre mot de passe...",
      imageStorage: "Vous pouvez télécharger des images sur votre profil...",
      emailUsage:
        "Nous pouvons utiliser votre adresse e-mail pour vous envoyer des mises à jour importantes et des notifications...",
      thirdPartySharing:
        "Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement explicite...",
      userContent:
        "Le contenu généré par l'utilisateur que vous créez peut être visible par d'autres utilisateurs de l'application...",
      // Add more key-value pairs for other sections and translations
    },
  },
  GER: {
    addSite: {
      site: "Website",
      mainCategory: "Hauptkategorie",
      select: "Auswählen",
      subCategory: "Unterkategorie",
      subSubCategory: "Unter-Unterkategorie",
      submit: "Senden",
    },
    adminCard: {
      adminCard: "Admin-Karte",
      goToAdminPanel: "Zum Admin-Panel gehen",
    },
    emailCaptureForm: {
      thereWasAnError: "Es gab einen Fehler",
      email: "E-Mail-Adresse",
      save: "Speichern",
    },
    emailUs: {
      toastEmailSent: "E-Mail Gesendet",
      toastEmailSentDescription: "Deine E-Mail wurde erfolgreich gesendet",
      toastEmailFailed: "E-Mail Fehlgeschlagen",
      toastEmailFailedDescription: "Deine E-Mail konnte nicht gesendet werden",
      nameInputPlaceholder: "Name",
      emailInputPlaceholder: "E-Mail-Adresse",
      emailBodyInputPlaceholder: "Schicke uns etwas Keksteig",
      sendEmail: "E-Mail Senden",
    },
    footer: {
      privacyPolicy: "Datenschutzrichtlinie",
    },
    navBarHamburgerMenu: {
      discover: "Entdecken",
      categories: "Kategorien",
      profile: "Profil",
      register: "Registrieren",
      leaderboard: "Bestenliste",
      addSite: "Website Hinzufügen",
      contactUs: "Kontaktiere uns",
      about: "Über Uns",
      login: "Einloggen",
      logout: "Ausloggen",
      language: "Sprache",
    },
    websiteWindow: {
      useAllCategories: "Alle Kategorien Nutzen",
      selectCategories: "Kategorien Auswählen",
    },
    landingPage: {
      thanksForJoining:
        "Danke für deine Anmeldung. Wir werden dir eine E-Mail schicken, sobald wir starten.",
      tagLine: "Bereit das Web neu zu entdecken?",
      getStarted: "Loslegen",
    },
    addSitePage: {
      addAWebsite: "Website Hinzufügen",
    },
    adminPage: {
      confirmOrDenySites: "Seiten Bestätigen / Ablehnen",
      addASite: "Website Hinzufügen",
    },
    categoriesPage: {
      selectACategory: "Kategorie Auswählen",
      modifyFavouriteCategories: "Lieblingskategorien Anpassen",
      confirmFavourites: "Favoriten Bestätigen",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "Favoriten Bestätigen",
      confirmationContext:
        "Bist du sicher, ohne Auswahl einer Kategorie fortzufahren? Du siehst dann alle Websites in der Datenbank. Keine Sorge, das kannst du später ändern.",
      confirm: "Bestätigen",
    },
    leaderboardPage: {
      userLiked: "Von Benutzer gemocht",
      websiteLikes: "Moste Populer Sitas",
      userCookiePoints: "Benutzer Kekspunkte",
    },
    profilePage: {
      liked: "👍 Gemocht",
      created: "Erstellt",
      coins: "🍪 Münzen",
      save: "Speichern",
      copiedToClipboard: "In die Zwischenablage kopiert",
      empty: "Leer",
      placeholderLocation: "Kekse Land",
      placeholderTagline:
        "CookieLander | Schlaf Liebender Mensch | Mag Websites | Keksmonster",
      referralText: "Verdiene 100 🍪 Münzen mit jeder Empfehlung!",
      referralCopy:
        "Teile diesen Link mit deinen Freunden, um 100 🍪 Münzen pro Empfehlung zu verdienen. Und verliere ihn nicht!",
      favouriteCategories: "Lieblingskategorien",
      sitesIHaveLiked: "Websites, Die Ich Gemocht Habe",
    },
    aboutPage: {
      title: "Über Uns",
      description:
        "Willkommen bei cookiejar! Wir sind hier, um dir ein zauberhaftes Browsing-Erlebnis zu bieten.",
      features: [
        {
          title: "Mag-Funktion",
          description:
            "Mag oder mag nicht Websites, um deine personalisierte Cookie-Erfahrung zu gestalten.",
          imageUrl: likeGif,
        },
        {
          title: "Profilbearbeitung",
          description: "Bearbeite dein Profil, um es einzigartig zu gestalten.",
          imageUrl: editProfile,
        },
        {
          title: "Cookie-Shop",
          description:
            "Kommt bald! Kaufe Power-Ups und mehr in unserem Cookie-Shop.",
          imageUrl: cookieStore,
        },
        {
          title: "Kategorien tauschen",
          description:
            "Tausche problemlos Kategorien aus und genieße maßgeschneiderte Seiten basierend auf deinen Vorlieben.",
          imageUrl: categoriesShowcase,
        },
        {
          title: "Internationale App",
          description:
            "Erlebe cookiejar in mehreren Sprachen, darunter Chinesisch, Spanisch, Englisch, Französisch, Deutsch, Italienisch und mehr.",
          imageUrl: multiLang,
        },
        {
          title: "Schritte zum Erhalten von Keks-Münzen",
          description:
            "Verdiene Keks-Münzen durch Empfehlungen:\n1. Teile deine Empfehlungs-URL.\n2. Erhalte Likes von anderen.\n3. Sieh zu, wie deine Keks-Münzen wachsen!",
          imageUrl: getCookieCoins,
        },
        {
          title: "Fähigkeit zum Einreichen von Websites",
          description:
            "Reiche deine Lieblings-Keks-Websites ein und trage zur Community bei.",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro:
        "Ihre Privatsphäre ist uns wichtig. Diese Datenschutzrichtlinie erläutert, wie wir Ihre Informationen sammeln, verwenden und offenlegen.",
      dataCollection:
        "Wir erfassen bestimmte Informationen, wenn Sie unsere Social-Media-App besuchen und verwenden...",
      dataUsage:
        "Die von uns erfassten Informationen können verwendet werden, um Ihr Erlebnis zu personalisieren...",
      cookies:
        "Wir verwenden Cookies und ähnliche Tracking-Technologien, um den Website-Traffic zu analysieren...",
      authentication:
        "Um auf bestimmte Funktionen zuzugreifen, können Sie ein Konto erstellen, indem Sie Ihre E-Mail-Adresse und Ihr Passwort verwenden...",
      imageStorage: "Sie können Bilder auf Ihr Profil hochladen...",
      emailUsage:
        "Wir können Ihre E-Mail-Adresse verwenden, um Ihnen wichtige Updates und Benachrichtigungen zu senden...",
      thirdPartySharing:
        "Wir geben Ihre persönlichen Informationen nicht ohne Ihre ausdrückliche Zustimmung an Dritte weiter...",
      userContent:
        "Der von Ihnen erstellte nutzergenerierte Inhalt kann für andere Benutzer der App sichtbar sein...",
      // Add more key-value pairs for other sections and translations
    },
  },
  ITA: {
    addSite: {
      site: "Sito",
      mainCategory: "Categoria Principale",
      select: "Seleziona",
      subCategory: "Sottocategoria",
      subSubCategory: "Sotto-sottocategoria",
      submit: "Invia",
    },
    adminCard: {
      adminCard: "Scheda Admin",
      goToAdminPanel: "Vai al Pannello di Amministrazione",
    },
    emailCaptureForm: {
      thereWasAnError: "Si è verificato un errore",
      email: "E-mail",
      save: "Salva",
    },
    emailUs: {
      toastEmailSent: "E-mail Inviata",
      toastEmailSentDescription: "La tua e-mail è stata inviata con successo",
      toastEmailFailed: "Invio E-mail Fallito",
      toastEmailFailedDescription: "La tua e-mail non è stata inviata",
      nameInputPlaceholder: "Nome",
      emailInputPlaceholder: "E-mail",
      emailBodyInputPlaceholder: "Invia un po' di Pasta di Biscotti!",
      sendEmail: "Invia E-mail",
    },
    footer: {
      privacyPolicy: "Informativa sulla Privacy",
    },
    navBarHamburgerMenu: {
      discover: "Scopri",
      categories: "Categorie",
      profile: "Profilo",
      register: "Registrati",
      leaderboard: "Classifica",
      addSite: "Aggiungi Sito",
      contactUs: "Contattaci",
      about: "Chi Siamo",
      login: "Accedi",
      logout: "Esci",
      language: "Lingua",
    },
    websiteWindow: {
      useAllCategories: "Usa Tutte le Categorie",
      selectCategories: "Seleziona Categorie",
    },
    landingPage: {
      thanksForJoining:
        "Grazie per l'iscrizione. Ti invieremo una e-mail appena avremo lanciato.",
      tagLine: "Pronto a Riscoprire il Web?",
      getStarted: "Inizia",
    },
    addSitePage: {
      addAWebsite: "Aggiungi un Sito Web",
    },
    adminPage: {
      confirmOrDenySites: "Conferma/Rifiuta Siti",
      addASite: "Aggiungi Sito",
    },
    categoriesPage: {
      selectACategory: "Seleziona una Categoria",
      modifyFavouriteCategories: "Modifica Categorie Preferite",
      confirmFavourites: "Conferma Preferiti",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "Conferma Preferiti",
      confirmationContext:
        "Sei sicuro di voler continuare senza selezionare alcuna categoria? Vedrai tutti i siti nel database. Non preoccuparti, potrai cambiarlo in seguito.",
      confirm: "Conferma",
    },
    leaderboardPage: {
      userLiked: "Piace a Utente",
      websiteLikes: "Mi Piace Sito Web",
      userCookiePoints: "Punti Cookie Utente",
    },
    profilePage: {
      liked: "👍 Mi Piace",
      created: "Creato",
      coins: "🍪 Monete",
      save: "Salva",
      copiedToClipboard: "Copiato negli Appunti",
      empty: "Vuoto",
      placeholderLocation: "Terra dei Biscotti",
      placeholderTagline:
        "CookieLander | Amante del Sonno | Apprezzatore di Siti Web | Mostro dei Biscotti",
      referralText: "Guadagna 100 🍪 monete con ogni riferimento!",
      referralCopy:
        "Condividi questo link con i tuoi amici per guadagnare 100 🍪 monete con ogni riferimento. E non perderlo!",
      favouriteCategories: "Categorie Preferite",
      sitesIHaveLiked: "Siti che Ho Apprezzato",
    },
    aboutPage: {
      title: "Chi Siamo",
      description:
        "Benvenuto su cookiejar! Siamo qui per offrirti un'esperienza di navigazione incantata.",
      features: [
        {
          title: "Funzionalità di Piacere",
          description:
            "Piace o non piace ai siti per creare la tua esperienza di cookie personalizzata.",
          imageUrl: likeGif,
        },
        {
          title: "Modifica del Profilo",
          description: "Modifica il tuo profilo per renderlo unico.",
          imageUrl: editProfile,
        },
        {
          title: "Negozio di Biscotti",
          description:
            "In Arrivo! Acquista power-up e altro dal nostro negozio di biscotti.",
          imageUrl: cookieStore,
        },
        {
          title: "Scambio di Categorie",
          description:
            "Scambia facilmente le categorie e goditi pagine personalizzate in base alle tue preferenze.",
          imageUrl: categoriesShowcase,
        },
        {
          title: "App Internazionale",
          description:
            "Esperienza di cookiejar in molte lingue, tra cui Cinese, Spagnolo, Inglese, Francese, Tedesco, Italiano e altro ancora.",
          imageUrl: multiLang,
        },
        {
          title: "Passaggi per Ottenere Monete Biscotto",
          description:
            "Guadagna monete biscotto tramite raccomandazioni:\n1. Condividi il tuo URL di riferimento.\n2. Ricevi Mi Piace da altri.\n3. Osserva crescere le tue monete biscotto!",
          imageUrl: getCookieCoins,
        },
        {
          title: "Abilità di Inviare Siti",
          description:
            "Invia i tuoi siti biscotto preferiti e contribuisci alla nostra community.",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro:
        "La tua privacy è importante per noi. Questa Informativa sulla privacy spiega come raccogliamo, utilizziamo e divulgiamo le tue informazioni.",
      dataCollection:
        "Raccogliamo alcune informazioni quando visiti e utilizzi la nostra app di social media...",
      dataUsage:
        "Le informazioni che raccogliamo possono essere utilizzate per personalizzare la tua esperienza...",
      cookies:
        "Utilizziamo cookie e tecnologie di tracciamento simili per analizzare il traffico del sito Web...",
      authentication:
        "Per accedere a determinate funzionalità, puoi creare un account utilizzando il tuo indirizzo email e la tua password...",
      imageStorage: "Puoi caricare immagini sul tuo profilo...",
      emailUsage:
        "Possiamo utilizzare il tuo indirizzo email per inviarti aggiornamenti importanti e notifiche...",
      thirdPartySharing:
        "Non condividiamo le tue informazioni personali con terzi senza il tuo esplicito consenso...",
      userContent:
        "Il contenuto generato dall'utente che crei potrebbe essere visibile per gli altri utenti dell'app...",
      // Add more key-value pairs for other sections and translations
    },
  },
  POR: {
    addSite: {
      site: "Site",
      mainCategory: "Categoria Principal",
      select: "Selecionar",
      subCategory: "Subcategoria",
      subSubCategory: "Sub-Subcategoria",
      submit: "Enviar",
    },
    adminCard: {
      adminCard: "Cartão de Administrador",
      goToAdminPanel: "Ir para o Painel de Administração",
    },
    emailCaptureForm: {
      thereWasAnError: "Ocorreu um erro",
      email: "E-mail",
      save: "Salvar",
    },
    emailUs: {
      toastEmailSent: "E-mail Enviado",
      toastEmailSentDescription: "Seu e-mail foi enviado com sucesso",
      toastEmailFailed: "Envio de E-mail Falhou",
      toastEmailFailedDescription: "Seu e-mail não pôde ser enviado",
      nameInputPlaceholder: "Nome",
      emailInputPlaceholder: "E-mail",
      emailBodyInputPlaceholder: "Envie um pouco de Massa de Biscoito!",
      sendEmail: "Enviar E-mail",
    },
    footer: {
      privacyPolicy: "Política de Privacidade",
    },
    navBarHamburgerMenu: {
      discover: "Descobrir",
      categories: "Categorias",
      profile: "Perfil",
      register: "Registrar",
      leaderboard: "Classificação",
      addSite: "Adicionar Site",
      contactUs: "Contate-nos",
      about: "Sobre",
      login: "Entrar",
      logout: "Sair",
      language: "Idioma",
    },
    websiteWindow: {
      useAllCategories: "Usar Todas as Categorias",
      selectCategories: "Selecionar Categorias",
    },
    landingPage: {
      thanksForJoining:
        "Obrigado por se inscrever. Enviaremos um e-mail assim que lançarmos.",
      tagLine: "Pronto para Redescobrir a Web?",
      getStarted: "Começar",
    },
    addSitePage: {
      addAWebsite: "Adicionar um Site",
    },
    adminPage: {
      confirmOrDenySites: "Confirmar / Negar Sites",
      addASite: "Adicionar Site",
    },
    categoriesPage: {
      selectACategory: "Selecionar uma Categoria",
      modifyFavouriteCategories: "Modificar Categorias Favoritas",
      confirmFavourites: "Confirmar Favoritos",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "Confirmar Favoritos",
      confirmationContext:
        "Tem certeza de que deseja continuar sem selecionar nenhuma categoria? Você verá todos os sites no banco de dados. Não se preocupe, você poderá mudar isso depois.",
      confirm: "Confirmar",
    },
    leaderboardPage: {
      userLiked: "Gostado pelo Usuário",
      websiteLikes: "Curtidas no Site",
      userCookiePoints: "Pontos de Cookie do Usuário",
    },
    profilePage: {
      liked: "👍 Gostei",
      created: "Criado",
      coins: "🍪 Moedas",
      save: "Salvar",
      copiedToClipboard: "Copiado para a Área de Transferência",
      empty: "Vazio",
      placeholderLocation: "Terra dos Cookies",
      placeholderTagline:
        "CookieLander | Amante de Dormir | Apreciador de Sites | Monstro de Cookies",
      referralText: "Ganhe 100 🍪 moedas a cada indicação!",
      referralCopy:
        "Compartilhe este link com seus amigos para ganhar 100 🍪 moedas a cada indicação. E não o perca!",
      favouriteCategories: "Categorias Favoritas",
      sitesIHaveLiked: "Sites que Gostei",
    },
    aboutPage: {
      title: "Sobre Nós",
      description:
        "Bem-vindo ao cookiejar! Estamos aqui para oferecer uma experiência de navegação encantadora.",
      features: [
        {
          title: "Recurso de Curtir",
          description:
            "Curta ou não curta os sites para criar sua experiência de cookie personalizada.",
          imageUrl: likeGif,
        },
        {
          title: "Edição de Perfil",
          description: "Edite seu perfil para torná-lo exclusivamente seu.",
          imageUrl: editProfile,
        },
        {
          title: "Loja de Cookies",
          description:
            "Em Breve! Compre power-ups e mais na nossa loja de cookies.",
          imageUrl: cookieStore,
        },
        {
          title: "Troca de Categorias",
          description:
            "Troque facilmente as categorias e desfrute de páginas personalizadas com base em suas preferências.",
          imageUrl: categoriesShowcase,
        },
        {
          title: "Aplicativo Internacional",
          description:
            "Experimente o cookiejar em vários idiomas, incluindo Chinês, Espanhol, Inglês, Francês, Alemão, Italiano e mais.",
          imageUrl: multiLang,
        },
        {
          title: "Passos para Obter Moedas de Cookies",
          description:
            "Ganhe moedas de cookies através de indicações:\n1. Compartilhe seu URL de indicação.\n2. Receba curtidas de outras pessoas.\n3. Veja suas moedas de cookies crescerem!",
          imageUrl: getCookieCoins,
        },
        {
          title: "Habilidade de Enviar Sites",
          description:
            "Envie seus sites de cookies favoritos e contribua para a nossa comunidade.",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro:
        "Sua privacidade é importante para nós. Esta Política de Privacidade explica como coletamos, usamos e divulgamos suas informações.",
      dataCollection:
        "Coletamos algumas informações quando você visita e usa nosso aplicativo de mídia social...",
      dataUsage:
        "As informações que coletamos podem ser usadas para personalizar sua experiência...",
      cookies:
        "Usamos cookies e tecnologias de rastreamento semelhantes para analisar o tráfego do site...",
      authentication:
        "Para acessar determinados recursos, você pode criar uma conta usando seu e-mail e senha...",
      imageStorage: "Você pode fazer upload de imagens para o seu perfil...",
      emailUsage:
        "Podemos usar seu endereço de e-mail para enviar atualizações importantes e notificações...",
      thirdPartySharing:
        "Não compartilhamos suas informações pessoais com terceiros sem o seu consentimento explícito...",
      userContent:
        "O conteúdo gerado pelo usuário que você cria pode ser visível para outros usuários do aplicativo...",
      // Add more key-value pairs for other sections and translations
    },
  },
  CHI: {
    addSite: {
      site: "网站",
      mainCategory: "主类别",
      select: "选择",
      subCategory: "子类别",
      subSubCategory: "子子类别",
      submit: "提交",
    },
    adminCard: {
      adminCard: "管理员卡片",
      goToAdminPanel: "前往管理员面板",
    },
    emailCaptureForm: {
      thereWasAnError: "出现错误",
      email: "电子邮件",
      save: "保存",
    },
    emailUs: {
      toastEmailSent: "电子邮件已发送",
      toastEmailSentDescription: "您的电子邮件已成功发送",
      toastEmailFailed: "电子邮件发送失败",
      toastEmailFailedDescription: "您的电子邮件发送失败",
      nameInputPlaceholder: "姓名",
      emailInputPlaceholder: "电子邮件",
      emailBodyInputPlaceholder: "发送一些饼干面团！",
      sendEmail: "发送电子邮件",
    },
    footer: {
      privacyPolicy: "隐私政策",
    },
    navBarHamburgerMenu: {
      discover: "发现",
      categories: "分类",
      profile: "个人资料",
      register: "注册",
      leaderboard: "排行榜",
      addSite: "添加网站",
      contactUs: "联系我们",
      about: "关于",
      login: "登录",
      logout: "登出",
      language: "语言",
    },
    websiteWindow: {
      useAllCategories: "使用所有分类",
      selectCategories: "选择分类",
    },
    landingPage: {
      thanksForJoining: "感谢您的注册。我们将在发布时通过电子邮件通知您。",
      tagLine: "准备好重新探索网络了吗？",
      getStarted: "开始",
    },
    addSitePage: {
      addAWebsite: "添加网站",
    },
    adminPage: {
      confirmOrDenySites: "确认/拒绝网站",
      addASite: "添加网站",
    },
    categoriesPage: {
      selectACategory: "选择一个分类",
      modifyFavouriteCategories: "修改喜爱分类",
      confirmFavourites: "确认喜欢",
    },
    categoriesExplorationModal: {
      confirmFavouriteHeader: "确认喜欢",
      confirmationContext:
        "您确定要继续而不选择任何分类吗？您将在数据库中看到所有网站。不用担心，您稍后可以更改此项。",
      confirm: "确认",
    },
    leaderboardPage: {
      userLiked: "用户点赞",
      websiteLikes: "网站点赞",
      userCookiePoints: "用户饼干积分",
    },
    profilePage: {
      liked: "👍 已点赞",
      created: "创建",
      coins: "🍪 金币",
      save: "保存",
      copiedToClipboard: "已复制到剪贴板",
      empty: "空",
      placeholderLocation: "饼干之地",
      placeholderTagline: "CookieLander | 热爱睡眠 | 喜欢网站 | 饼干怪兽",
      referralText: "每个推荐可获得 100 🍪 金币奖励！",
      referralCopy:
        "与朋友分享此链接，每次推荐可获得 100 🍪 金币奖励。请不要遗失链接！",
      favouriteCategories: "喜爱分类",
      sitesIHaveLiked: "我点赞的网站",
    },
    aboutPage: {
      title: "关于我们",
      description: "欢迎来到 cookiejar！我们致力于提供一流的浏览体验。",
      features: [
        {
          title: "点赞功能",
          description: "点赞或取消点赞网站，以创建个性化的 Cookie 体验。",
          imageUrl: likeGif,
        },
        {
          title: "编辑个人资料",
          description: "编辑个人资料，使其变得独具特色。",
          imageUrl: editProfile,
        },
        {
          title: "Cookie 商店",
          description: "即将推出！从我们的 Cookie 商店购买强化道具和更多内容。",
          imageUrl: cookieStore,
        },
        {
          title: "切换分类",
          description: "轻松切换分类，根据您的偏好享受定制页面。",
          imageUrl: categoriesShowcase,
        },
        {
          title: "国际化应用",
          description:
            "在多种语言中体验 cookiejar，包括中文、西班牙语、英语、法语、德语、意大利语等。",
          imageUrl: multiLang,
        },
        {
          title: "获得 Cookie 币的步骤",
          description:
            "通过推荐获得 Cookie 币：\n1. 分享您的推荐链接。\n2. 收到其他人的点赞。\n3. 观察您的 Cookie 币不断增长！",
          imageUrl: getCookieCoins,
        },
        {
          title: "提交网站的能力",
          description: "提交您喜欢的 Cookie 网站，为我们的社区做出贡献。",
          imageUrl: addSiteShowcase,
        },
      ],
    },
    privacyPolicyPage: {
      intro: "您的隐私对我们很重要。本隐私政策解释了我们如何收集、使用和披露您的信息。",
      dataCollection: "当您访问和使用我们的社交媒体应用时，我们会收集一些信息。这些信息包括您直接提供的信息，如您的姓名、头像、电子邮件地址以及您在应用上分享或与之互动的任何内容。我们还会收集有关您与其他用户的互动的信息，如赞、评论和关注。",
      dataUsage: "我们收集的信息可能会用于个性化您在应用上的体验，并改善我们的服务。我们使用这些数据来定制您的内容流、推荐相关连接，并增强您的整体应用体验。我们还可以使用这些信息来分析趋势、解决问题，并进行研究，以改进我们的功能和功能。",
      cookies: "我们使用 cookie 和类似的跟踪技术来分析网站流量，并改善用户在应用上的体验。这些技术使我们能够记住您的偏好、了解您如何使用应用，并为您提供更个性化的体验。您可以通过浏览器设置管理您的 cookie 偏好。",
      authentication: "为了访问某些功能，您可以使用您的电子邮件和密码创建一个帐户。这些信息将安全地存储，并用于在您登录时验证您的身份。您还可以选择使用第三方验证服务登录，在这种情况下，我们会从这些服务中获取有限的信息，以创建和管理您的帐户。",
      imageStorage: "您可以将图片上传到您的个人资料。这些图片将安全地存储在我们的数据库中，并与您的帐户关联。您可以根据隐私设置控制您的图片的可见性。我们不会将您的图片用于广告目的，也不会未经您的同意与第三方共享。",
      emailUsage: "我们可能会使用您的电子邮件向您发送与您的帐户和应用使用相关的重要更新和通知。这些通信可能包括与帐户相关的信息、公告和推广材料。您可以在帐户设置中管理您的通信偏好。",
      thirdPartySharing: "我们不会在没有您明确同意的情况下与第三方分享您的个人信息。但是，如果您选择将您的帐户与第三方服务连接（如在其他社交媒体平台上共享内容），根据您的偏好，某些信息可能会与这些服务共享。",
      userContent: "您创建的用户生成内容可能对应用程序的其他用户可见，取决于您的隐私设置。这包括帖子、评论、个人资料信息以及您选择共享的任何其他内容。请谨慎分享您的信息，因为它可能变为公开可见。",
      // Add more key-value pairs for other sections and translations
    },
  },
};
