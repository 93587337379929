import { create } from "zustand";
import { copy } from "../configs/copy";

/**
 * This store is used to store the main state of the application
 * This will be used to store information as follows:
 * - General Purpose Functions
 * - General Purpose Variables
 * - General Purpose States & libraries Like Supabase
 * --
 * Example:
 * @example
 * import useAppStore from './stores/app';
 * // To access the history function / object from the store
 *  const setNavigate = useAppStore((state) => state.setHistory)
 *  const navigateTo = useAppStore((state) => state.history)
 *  setHistory({ ReactHistoryHookInitialized })
 *  // To push a new route to the history, and not fully reload the page
 *  navigateTo('/login')
 */
const useAppStore = create((set, get) => ({
  // State to handle navigation of the application
  setNavigate: (navigatorFunction) => set({ navigateTo: navigatorFunction }),
  navigateTo: () => console.log("Navigate function not initialized"),
  // ---
  // State to provide Supabase to the application
  // ---
  toast: () => {},
  setToast: (toast) => set({ toast }),
  usefulToast: () => {},
  setUsefulToast: (usefulToast) => set({ usefulToast }),
  extraUsefulToast: () => {},
  setExtraUsefulToast: (usefulToast) => set({ usefulToast }),
  // ---
  setAuthModal: (authModal) => set({ authModal }),
  authModal: {
    onOpen: () => {
      console.log("hi");
    },
    onClose: () => {
      console.log("hi");
    },
    isOpen: true,
  },
  // ---
  pub: JSON.parse(localStorage.getItem("a-a-2214-ee")) || true,
  setIsMobile: (isMobile) => set({ isMobile: isMobile }),
  isMobile: false,
  //   ---
  language: localStorage.getItem("language") || "ENG",
  toggleLanguage: () => {
    const languages = Object.keys(copy);
    const currentlanguage = get().language;

    const index = languages.indexOf(currentlanguage);
    const nextLanguage = languages[index + 1] || languages[0];

    localStorage.setItem("language", nextLanguage);
    set({ language: nextLanguage });
  },
}));

export default useAppStore;
