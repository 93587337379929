import { useBreakpointValue, Flex, Center } from "@chakra-ui/react";

import { colours } from "../../configs/styles";
import Logo from "../../UI/Logo";
import { NavBarHamburgerMenu } from "../../components";

import { useAppStore } from "../../stores";

/*
* This is the header component that is used in all the pages except the discover page.
* This component is used to navigate between pages.
*/
export default function Header({ setView }) {
  const { navigateTo } = useAppStore((state) => state);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const barWidth = useBreakpointValue({
    base: "100vw",
    sm: "100vw",
    md: "65vw",
    lg: "75vw",
  });
  const mobileHamburgerMenu = useBreakpointValue({
    base: true,
    md: false,
    lg: false,
  });
  
  return (
    <>
      {true && (
        <Flex
          w="100vw"
          bg={colours.primary}
          justify={"center"}
          position={"fixed"}
          zIndex={"10000"}
        >
          <Flex w={barWidth} justify="space-between" align="center" p="2">
            <Logo />
            <Flex>
              <Center gap="4">
                <NavBarHamburgerMenu setView={setView} />
              </Center>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}
