import React, { useState, useEffect } from "react";
import {
  Flex,
  Image,
  Text,
  Button,
  Heading,
  Box,
  Card,
  Center,
  useBreakpointValue,
  CardHeader,
} from "@chakra-ui/react";

import { EmailCaptureForm } from "../../../components/EmailCapture";
import logo from "../../../assets/logo.svg";

// styles
import { colours } from "../../../configs/styles";

// import springImage1 from '../../../assets/backgrounds/spring_1.webp';
// import springImage2 from '../../../assets/backgrounds/spring_2.webp';
import springImage3 from "../../../assets/backgrounds/spring_3.webp";

// import summerImage1 from '../../../assets/backgrounds/summer_1.webp';
// import summerImage2 from '../../../assets/backgrounds/summer_2.webp';
import summerImage3 from "../../../assets/backgrounds/summer_3.webp";

// import autumnImage1 from '../../../assets/backgrounds/autumn_1.webp';
// import autumnImage2 from '../../../assets/backgrounds/autumn_2.webp';
import autumnImage3 from "../../../assets/backgrounds/autumn_3.webp";

// import winterImage1 from '../../../assets/backgrounds/winter_1.webp';
// import winterImage2 from '../../../assets/backgrounds/winter_2.webp';
import winterImage3 from "../../../assets/backgrounds/winter_3.webp";

import { choose } from "../../../functions/utils";
import useAppStore from "../../../stores/app";
import { copy as C } from "../../../configs/copy";
import { CardBody } from "reactstrap";
import { useSiteStore, useUserStore } from "../../../stores";

export default function HeaderPresentation2({ pub }) {
  const { navigateTo, isMobile } = useAppStore((state) => state);
  const { isLoggedIn } = useUserStore((state) => state);
  const justifyContent = useBreakpointValue({ sm: "center", md: "center" });
  const [isOpen, setIsOpen] = useState(false);
  const acWidth = useBreakpointValue({ base: "90%", md: "350px" });
  const widthManage = useBreakpointValue({ base: "100%", md: "25%" });
  const paddingDisable = useBreakpointValue({ base: true, md: false });
  // const [currentBg, setCurrentBg] = useState(choose([springImage1, springImage2, springImage3])); // Initial season: spring
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [complete, setComplete] = useState(false);
  const [currentBg, setCurrentBg] = useState(
    choose([springImage3, summerImage3, autumnImage3, winterImage3])
  ); // Initial season: spring
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].landingPage;

  const totalSitesThusFar = useSiteStore((state) => state.totalSitesThusFar);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    const asyncf = async () => {
      totalSitesThusFar()
        .then((res) => {
          setTotal(res);
        })
        .catch((err) => {
          console.log(err);
          setTotal(Math.floor(Math.random() * 1000) + 1);
        });
    };
    asyncf();
  }, []);

  // useEffect(() => {
  //   fetch('https://ipinfo.io/json')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const { city, country, loc } = data;
  //       const [latitude] = loc.split(',');

  //       const currentMonth = new Date().getMonth() + 1;
  //       // const currentSeason = determineSeason(currentMonth, latitude);
  //       // const backgroundImage = getBackgroundImage(currentSeason);
  //       // setCurrentBg(backgroundImage);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching IP address and location:', error);
  //     });
  // }, []);

  // const determineSeason = (month, latitude) => {
  //   if ((latitude >= 0 && month >= 3 && month <= 5) || (latitude < 0 && (month === 1 || (month >= 9 && month <= 11)))) {
  //     return 'spring';
  //   } else if ((latitude >= 0 && month >= 6 && month <= 8) || (latitude < 0 && (month >= 2 && month <= 4)))  {
  //     return 'summer';
  //   } else if ((latitude >= 0 && month >= 9 && month <= 11) || (latitude < 0 && (month >= 6 && month <= 8))) {
  //     return 'autumn';
  //   } else {
  //     return 'winter';
  //   }
  // };

  // const getBackgroundImage = (season) => {
  //   switch (season) {
  //     case 'spring':
  //       return choose([springImage1, springImage2, springImage3]);
  //     case 'summer':
  //       return choose([summerImage1, summerImage2, summerImage3]);
  //     case 'autumn':
  //       return choose([autumnImage1, autumnImage2, autumnImage3]);
  //     case 'winter':
  //       return choose([winterImage1, winterImage2, winterImage3]);
  //     default:
  //       return choose([springImage1, springImage2, springImage3]); // Default to spring
  //   }
  // };

  return (
    <>
      <Image
        h="100vh"
        objectFit="cover"
        w="100vw"
        alt="An image background"
        pos="fixed"
        right="0"
        src={currentBg}
        zIndex="-1"
        mt="-125px"
      />
      <Flex w="100vw" overflowX="hidden" mt="-60px">
        <Flex
          flexDir="column"
          w={widthManage}
          align="center"
          justify={justifyContent}
          h="100vh"
          ml={paddingDisable ? "" : "3rem"}
          mt={isMobile ? "100px" : ""}
          p={isMobile ? "4" : "0"}
        >
          {!pub ? (
            <Box w={acWidth}>
              <EmailCaptureForm
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                setComplete={setComplete}
                header={<Heading size="xl">{copy.tagLine}</Heading>}
              >
                <Text fontSize="large" fontWeight="bold" w={acWidth}>
                  {copy.thanksForJoining}
                </Text>
              </EmailCaptureForm>
            </Box>
          ) : (
            <>
              <Card
                bg="rgba(255, 255, 255, 0.2)"
                backdropFilter="auto"
                backdropBlur="15px"
                border="1px solid rgba(255, 255, 255, 0.5)"
                mb="15px"
              >
                <CardHeader>
                  <Center gap="2" flexDir="rows">
                    <Image src={logo} h="55px" w="55px" mr="auto" alt="Logo" />
                    <Heading fontSize="xl">
                      Dive into exciting categories. <br /> Discover new
                      websites.
                    </Heading>
                  </Center>
                </CardHeader>
                <CardBody display="flex" w="100%" justifyContent="center">
                  <Flex p="4" flexDir={"column"} gap="1">
                    <Button
                      onClick={async () => {
                        navigateTo("categories");
                      }}
                      bg={colours.primary}
                      w="100%"
                      position={"sticky"}
                      color={colours.text.primary}
                    >
                      Get Started
                    </Button>
                    <Text fontSize="sm" textAlign="center" mb="-2.5">
                      {total} total sites.
                    </Text>
                  </Flex>
                </CardBody>
              </Card>

            </>
          )}
        </Flex>
      </Flex>
    </>
  );
}
