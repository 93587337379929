import { useEffect } from "react";
import { Box, Center, Flex, Heading, Image } from "@chakra-ui/react";
import { Logo } from "../../UI";

const ConfirmEmail = () => {
  useEffect(() => {
    localStorage.setItem("visitProfile", true);
    console.log("visitProfile", localStorage.getItem("visitProfile"));
  }, []);

  return (
    <>
      <Center>
        <Flex gap="4" flexDir="column" justify="center">
          <p>Check your email and click the verification link.</p>
          <Center>
            <Logo />
          </Center>
        </Flex>
      </Center>
    </>
  );
};

export default ConfirmEmail;
