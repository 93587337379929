import { useState, useEffect } from "react";

import {
  Flex,
  Avatar,
  Heading,
  Input,
  Button,
  Text,
  Box,
  Image,
  useToast,
  Badge,
  SimpleGrid,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";
import { SettingsIcon, CopyIcon, CloseIcon } from "@chakra-ui/icons";

import { colours } from "../../configs/styles";
import { copy as C } from "../../configs/copy";
import { AiFillLike } from "react-icons/ai";
import { RxCookie } from "react-icons/rx";
import Confetti from "react-confetti";
import {
  CustomSpinner,
  SizeIncreaseHover,
  StatsMemberSinceBadge,
} from "../../UI";
import { LikedSiteCard } from "./LikedSites";

import { useAppStore, useUserStore } from "../../stores";
import GeneralLabel from "../../UI/GeneralLabel";

const ProfilePage = () => {
  const user = useUserStore((state) => state.user);
  const nonprof = useUserStore((state) => state.profile);
  const {
    authModal: { onOpen },
    navigateTo,
  } = useAppStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getOtherProfile,
    updateProfile,
    getOtherLikedSites,
    getOtherCategories,
    getCategories,
    deleteCategory,
  } = useUserStore((state) => state);
  console.log("current user", user);
  const id = window.location.pathname.replace("/profile/", "");
  let cID = id.substring(1);
  cID = cID.toLocaleLowerCase();
  const { isMobile } = useAppStore((state) => state);
  const [profileUser, setProfileUser] = useState({ categories: [] });
  const [profileEdit, setProfileEdit] = useState(false);
  const [profileLikedSites, setProfileLikedSites] = useState([]);
  const copyToast = useToast();
  const [profileEditConfig, setProfileEditConfig] = useState({
    username: profileUser?.username,
    location: profileUser?.location,
    tagLine: profileUser?.tagLine,
    bio: profileUser?.bio,
    socials: profileUser?.socials || [],
    avatar_url: profileUser?.avatar_url,
    banner: profileUser?.banner,
  });
  const [unsaved, setUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLikedSitesLoading, setIsLikedSitesLoading] = useState(false);
  const [confetti, setConfetti] = useState(false);

  const currentPage = window.location.pathname.substring(1);
  const likedSites = useUserStore((state) => state.likedSites);

  const lang = useAppStore((state) => state.language);
  const copy = C[lang].profilePage;

  useEffect(() => {
    const asyncf = async () => {
      let profile = {};
      if (id == "@null") {
        profile = nonprof;
        if (Object.keys(nonprof) == 0) onOpen();
      }

      if (
        id[0] == "@" &&
        id.substring(1) != profile.username &&
        id != "@null"
      ) {
        profile = await getOtherProfile(setProfileUser, setIsLoading, cID);
      } else if (id != "@null") {
        setProfileUser(useUserStore.getState().profile);
        profile = useUserStore.getState().profile;
        console.log("profile user", useUserStore.getState().profile);
        console.log(currentPage, "current page");
      }

      if (id == "") {
        setProfileUser(useUserStore.getState().profile);
      }

      if (currentPage == "profile/edit") {
        setProfileEdit(true);
      }

      let iid = ["e", "d"].includes(cID) ? user?.session?.user?.id : cID;

      if (currentPage == "profile/me" || currentPage == "profile/edit") {
        setProfileLikedSites([...new Set(likedSites.map((e) => e))]);
      } else {
        getOtherLikedSites(iid, setIsLikedSitesLoading, setProfileLikedSites);
      }

      const categories = await getOtherCategories(profile);
      console.log(categories);

      setProfileUser({ ...profile, categories });

      if (profile?.username != "") {
        if (!profile?.username) {
          navigateTo("404");
        }
      }
    };

    asyncf();

    return () => {};
  }, [currentPage, id, cID]);

  const handleImageUpload = (e, item) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log("Setting image for", item);
        setProfileEditConfig((prevConfig) => ({
          ...prevConfig,
          [item]: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const [count, setCount] = useState(0);
  // Check if the profile was edited to warn the user to not leave the page
  useEffect(() => {
    setCount(count + 1);
    if (count + 1 > 2) {
      setUnsaved(true);
    }
  }, [profileEditConfig]);

  let imageKey =
    profileEditConfig.banner || profileUser?.banner || "default_banner";
  useEffect(() => {
    imageKey = imageKey + "a";
  }, [profileUser]);

  useEffect(() => {
    if (localStorage.getItem("confetti")) {
      localStorage.removeItem("confetti");
      setConfetti(true);
    }
  }, []);
  return (
    <Flex
      minH="100vh"
      minW="100vw"
      align="center"
      justify={isLoading ? "center" : "start"}
    >
      {confetti && <Confetti tweenDuration={10000} />}
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <Flex flexDir={"column"}>
          <Flex justify="center" flexDir="column" align="center">
            <label htmlFor="backdrop-upload">
              {profileEdit && (
                <Input
                  id="backdrop-upload"
                  type="file"
                  display="none"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageUpload(e, "banner", profileEditConfig);
                  }}
                ></Input>
              )}
              <Flex
                _hover={{
                  background: profileEdit && "rgba(255, 255, 255, 0.5)",
                  cursor: "pointer",
                }}
                h="fit-content"
                mt="-150px"
                background={""}
                onClick={() => {
                  if (
                    !profileEdit &&
                    (currentPage == "profile/me" ||
                      currentPage == "profile/edit")
                  )
                    setProfileEdit(true);
                }}
              >
                <Image
                  key={imageKey}
                  src={
                    profileEditConfig?.banner ||
                    profileUser?.banner ||
                    "https://source.unsplash.com/random/900×700/?city%20skyline"
                  }
                  w="100vw"
                  h="80vh"
                  objectFit={"cover"}
                  zIndex={"-1"}
                />
              </Flex>
            </label>
            <Card
              position={"relative"}
              top="-400"
              w={isMobile ? "100vw" : "70%"}
              display={"flex"}
              align="center"
              mt="100px"
              bg={colours.secondary}
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.5)"
            >
              <CardHeader w="100%">
                <Flex spacing="4" flexDir="column" textAlign={"center"}>
                  <Flex
                    flex="1"
                    gap="4"
                    flexWrap="wrap"
                    w="100%"
                    justify="center"
                    mt={isMobile ? "20" : 0}
                    align={"center"}
                  >
                    <Flex
                      mr="auto"
                      gap="5"
                      justify={isMobile ? "space-between" : "space-around"}
                      wrap="wrap"
                    >
                      <Badge
                        p="2"
                        minW="125px"
                        w="fit-content"
                        display="flex"
                        rounded="md"
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap="2"
                      >
                        <Flex fontSize={"2xl"} align="center">
                          <AiFillLike />
                        </Flex>
                        {(profileUser?.like_count || 0).toLocaleString()}
                      </Badge>
                      <StatsMemberSinceBadge
                        icon={"Member Since"}
                        stat={
                          profileUser?.created_at?.substring(5, 7) +
                            "-" +
                            profileUser?.created_at?.substring(8, 10) +
                            "-" +
                            profileUser?.created_at?.substring(0, 4) || "???"
                        }
                      />
                      <Badge
                        p="2"
                        minW="125px"
                        w="fit-content"
                        display="flex"
                        rounded="md"
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap="2"
                      >
                        <Flex fontSize={"2xl"} align="center">
                          <RxCookie />
                        </Flex>
                        {(profileUser?.coins || 0).toLocaleString()} Points
                      </Badge>
                    </Flex>
                    <Flex pos="absolute" top="-10">
                      <label htmlFor="profile-upload">
                        <Avatar
                          key={`${imageKey}+1`}
                          _hover={
                            profileEdit
                              ? {
                                  filter: "blur(5px)",
                                  _after: {
                                    background: "rgba(255, 255, 255, 1)",
                                  },
                                }
                              : {}
                          }
                          size="2xl"
                          name={profileUser?.username || "LO"}
                          src={
                            profileEditConfig?.avatar_url ||
                            profileUser?.avatar_url ||
                            `https://altar.berrysauce.me/generate?data=${profileUser?.username}`
                          }
                        />

                        {profileEdit && (
                          <Input
                            type="file"
                            id="profile-upload"
                            accept="image/*"
                            display="none"
                            onChange={(e) =>
                              handleImageUpload(
                                e,
                                "avatar_url",
                                profileEditConfig
                              )
                            }
                          />
                        )}
                      </label>
                    </Flex>
                    <Flex
                      ml="auto"
                      mr={isMobile ? "" : "20"}
                      justify="space-between"
                      gap="2"
                      wrap="wrap"
                    >
                      <Flex>
                        {nonprof?.username == cID || cID == "null" ? (
                          <Button
                            w="100%"
                            // BLue when not edit, blue when edit
                            colorScheme={profileEdit ? "orange" : "blue"}
                            color="white"
                            onClick={async () => {
                              if (profileUser.id == user.session.user.id) {
                                if (profileEdit) {
                                  updateProfile(
                                    profileEditConfig,
                                    setIsSaving,
                                    setProfileEdit,
                                    setProfileUser
                                  );
                                } else {
                                  setProfileEdit(true);
                                }
                              }
                            }}
                          >
                            {profileEdit && isSaving ? (
                              <Spinner />
                            ) : !profileEdit ? (
                              <>
                                <Badge
                                  bg="transparent"
                                  color="white"
                                  colorScheme="blue"
                                >
                                  Edit Profile
                                </Badge>
                                <SettingsIcon />
                              </>
                            ) : (
                              <>{copy.save}</>
                            )}
                          </Button>
                        ) : null}
                        <Box mt="2" ml="2">
                          <GeneralLabel
                            labelText={
                              "This is the profile page, Here you can view your own statistics & the statistics of others!"
                            }
                          />
                        </Box>
                      </Flex>

                      {profileEdit && (
                        <Button
                          w="100%"
                          bg={colours.primary}
                          colorScheme="blue"
                          color="white"
                          onClick={() => setProfileEdit(false)}
                        >
                          Cancel
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody w="100%" mt="1">
                <Flex w="100%" align="center" justify="center" mb="5">
                  <Flex
                    w={
                      profileEdit && isMobile
                        ? "100%"
                        : profileEdit
                        ? "50%"
                        : "100%"
                    }
                    flexDir="column"
                    gap="4"
                    textAlign={"center"}
                  >
                    {profileEdit ? (
                      <SizeIncreaseHover>
                        <Flex flexDir="column">
                          <Heading fontSize="sm" mr="auto">
                            Username:
                          </Heading>
                          <Input
                            bg="white"
                            type="text"
                            onChange={(e) =>
                              setProfileEditConfig((prevConfig) => ({
                                ...prevConfig,
                                username: e.target.value,
                              }))
                            }
                            placeholder={
                              profileUser?.username
                                ?.replace(" ", "")
                                .replace("/", "")
                                .replace("@", "") || "John Doe"
                            }
                          />
                        </Flex>
                      </SizeIncreaseHover>
                    ) : (
                      <Heading size="lg" textAlign={"center"}>
                        @{profileUser?.username || copy.empty}{" "}
                        <Badge p="2" rounded="md" bg={colours.action.primary}>
                          {profileUser?.user_type == "owner"
                            ? "founder"
                            : profileUser?.user_type || "n/a"}
                        </Badge>
                      </Heading>
                    )}
                    {profileEdit ? (
                      <SizeIncreaseHover>
                        <Flex flexDir="column">
                          <Heading mr="auto" fontSize={"sm"}>
                            Location:
                          </Heading>
                          <Input
                            bg="white"
                            type="text"
                            onChange={(e) =>
                              setProfileEditConfig((prevConfig) => ({
                                ...prevConfig,
                                location: e.target.value,
                              }))
                            }
                            placeholder={
                              profileUser?.location || copy.placeholderLocation
                            }
                          />
                        </Flex>
                      </SizeIncreaseHover>
                    ) : (
                      <Heading size="sm">
                        📍 {profileUser?.location || copy.placeholderLocation}
                      </Heading>
                    )}
                    {profileEdit ? (
                      <SizeIncreaseHover>
                        <Flex flexDir="column">
                          <Heading mr="auto" fontSize={"sm"}>
                            Bio:
                          </Heading>
                          <Input
                            bg="white"
                            type="text"
                            onChange={(e) =>
                              setProfileEditConfig((prevConfig) => ({
                                ...prevConfig,
                                tagLine: e.target.value,
                              }))
                            }
                            placeholder={
                              profileUser?.tagLine || copy.placeholderTagline
                            }
                          />
                        </Flex>
                      </SizeIncreaseHover>
                    ) : (
                      <Text>
                        {profileUser?.tagLine || copy.placeholderTagline}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                {profileUser.username == nonprof?.username && (
                  <Flex
                    align="center"
                    justify="space-between"
                    mt="2"
                    bg={colours.primary}
                    p="3"
                    color="white"
                    flexDir={isMobile ? "column" : "row"}
                  >
                    <Text>Earn 100 🍪 POINTS with every referral!</Text>
                    <Button
                      bg={colours.action.primary}
                      color={colours.text.primary}
                      colorScheme="orange"
                      onClick={() => {
                        localStorage.setItem("started", "yes");
                        navigator.clipboard.writeText(
                          "https://" +
                            window.location.host +
                            "/referral/" +
                            profileUser?.username
                        );
                        copyToast({
                          title: copy.copiedToClipboard,
                          description: copy.referralCopy,
                          status: "success",
                          duration: 20000,
                          isClosable: true,
                        });
                      }}
                    >
                      <CopyIcon mr="2" />
                      COPY REFERRAL LINK
                    </Button>
                  </Flex>
                )}
              </CardBody>
              <Flex flexDir={"column"} w="100%" gap="4" p="5">
                <Box>
                  <Heading fontSize="lg" mb="3">
                    {copy.favouriteCategories}
                  </Heading>
                  <SimpleGrid minChildWidth={"200px"} gap="2">
                    {isLikedSitesLoading ? (
                      <CustomSpinner />
                    ) : (
                      <>
                        {(profileUser?.categories || []).map(
                          (category, index) => (
                            <Badge
                              key={index + "category"}
                              color={colours.action.primary}
                              h="fit-content"
                              display="flex"
                              justify="space-between"
                              align="center"
                              textAlign="center"
                              maxW="200px"
                            >
                              <Flex align="center" textAlign="center">
                                {category}
                              </Flex>
                              {nonprof.username == cID ? (
                                <Button
                                  ml="auto"
                                  onClick={() => {
                                    console.log(category);
                                    deleteCategory(
                                      category,
                                      setIsLikedSitesLoading
                                    );
                                    console.log(category);
                                    setProfileUser({
                                      ...profileUser,
                                      categories: profileUser.categories.filter(
                                        (e) => e != category
                                      ),
                                    });
                                  }}
                                >
                                  <CloseIcon>x</CloseIcon>
                                </Button>
                              ) : null}
                            </Badge>
                          )
                        )}
                      </>
                    )}
                    <Flex w="200px"></Flex>
                    <Flex w="200px"></Flex>
                    <Flex w="200px"></Flex>
                    <Flex w="200px"></Flex>
                    <Flex w="200px"></Flex>
                    <Flex w="200px"></Flex>
                  </SimpleGrid>
                </Box>
                <Box>
                  <Heading fontSize="lg" mb="3">
                    {copy.sitesIHaveLiked}
                  </Heading>
                  <SimpleGrid gap="2" cols={4} minChildWidth={"300px"}>
                    {isLikedSitesLoading ? (
                      <CustomSpinner />
                    ) : (
                      <>
                        {(profileLikedSites || []).map((site, index) => (
                          <LikedSiteCard
                            site={site}
                            key={index + "liked_site"}
                          />
                        ))}
                      </>
                    )}
                  </SimpleGrid>
                </Box>
              </Flex>
            </Card>
          </Flex>
          <></>
        </Flex>
      )}
    </Flex>
  );
};

export default ProfilePage;
