import { useState } from "react";
import { ViewIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Textarea,
  Button,
  VStack,
  Box,
  Flex,
  ModalCloseButton,
  ModalContent,
  Badge,
  Spacer,
  Avatar,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { colours } from "../../configs/styles";
import { StatsBadge } from "../../UI";
import { AiOutlineComment, AiFillLike, AiFillDislike } from "react-icons/ai";

import { useAppStore, useSiteStore, useUserStore } from "../../stores";

/*
 * This is the component that shows the comments & stats of a site.
 */
export default function SiteInfoCard({ comments, site }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const isMobile = useAppStore((state) => state.isMobile);
  const onModalOpen = useAppStore((state) => state.authModal.onOpen);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [newComment, setNewComment] = useState("");
  const { addComment } = useSiteStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);

  const handleCommentSubmit = () => {
    if (newComment.trim() !== "") {
      addComment(newComment, setShowCommentInput, setIsLoading);

      setNewComment("");
    }
  };

  return (
    <Box ml="auto" p="2">
      <Button
        variant={"solid"}
        size="sm"
        onClick={onOpen}
        p="2"
        leftIcon={<AiOutlineComment />}
        bg="white"
      >
        <Text>Comments</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="fit-content">
          <ModalHeader>Add a comment.</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="60vh" overflow="scroll">
            <VStack spacing={4}>
              <Flex justify="space-evenly" w="100%" gap="3" wrap="wrap">
                <StatsBadge icon={<ViewIcon />} stat={site.views} />
                <StatsBadge icon={<AiFillLike />} stat={site.likes} />
                <StatsBadge icon={<AiFillDislike />} stat={site.dislikes} />
                <StatsBadge
                  icon={<AiOutlineComment />}
                  stat={comments.length}
                />
              </Flex>

              {!showCommentInput &&
                comments.map((comment, index) => (
                  <CommentCard
                    key={index}
                    comment={comment.content}
                    username={comment?.user?.username}
                    image={comment?.user?.avatar_url}
                    dateCreated={comment.created_at.substring(0, 10)}
                  />
                ))}
            </VStack>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="space-between" w="100%">
            <Flex w="100%" gap="4" align="center">
              <Textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
                size="sm"
                w="60vw"
              />

              <Box>
                <Button
                  bg={colours.primary}
                  onClick={handleCommentSubmit}
                  colorScheme="blue"
                  disabled={isLoading}
                  w={isMobile ? "25vw" : "15vw"}
                >
                  {isLoading ? <Spinner /> : "Submit"}
                </Button>
                {/* Will show a little dropdown that allows users to report or visit the original site. */}
              </Box>
            </Flex>
            <Flex w="100%" align="center">
              <Spacer />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const CommentCard = ({ comment, username, image, dateCreated }) => {
  const navigateTo = useAppStore((state) => state.navigateTo);

  return (
    <Box
      display="flex"
      w="100%"
      alignItems="center"
      gap="4"
      p="2"
      bg="gray.100"
      rounded="md"
      border={"1px solid black"}
    >
      <Avatar size="md" src={image} />
      <VStack flexDir="column" spacing="1" align="start">
        <Box
          fontWeight="bold"
          onClick={() => navigateTo("profile/@" + username)}
        >
          {username} <Badge>{dateCreated}</Badge>
        </Box>
        <Box overflowWrap="break-word" wordBreak={"break-all"} hyphens="manual">
          {comment}
        </Box>
      </VStack>
    </Box>
  );
};
