/*
This file is for all the general styles of the app, such as colors, fonts, etc.
Obviously I don't hae the skill to make us be able to change the entire layout of the app ( I can but then the code will be unreadable )
So this is just for the general styles of the app.
*/

export const colours = {
  primary: "#3a6ea5",
  secondary: "#EBEBEB",
  tertiary: "#ff6700",
  action: {
    primary: "#ff6700",
    secondary: "#004e98",
  },
  text: {
    primary: "white",
    secondary: "black",
    tertiary: "gray.700",
    quaternary: "gray.500",
  },
};
