import {
  AddIcon,
  AtSignIcon,
  CheckCircleIcon,
  DragHandleIcon,
  HamburgerIcon,
  PhoneIcon,
  QuestionOutlineIcon,
  StarIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";

import {
  Menu,
  MenuButton,
  MenuList,
  Icon,
  Image,
  MenuItem,
  IconButton,
  Box,
} from "@chakra-ui/react";

import { copy as C } from "../../configs/copy";
import logo from "../../assets/logo.svg";
import { colours } from "./../../configs/styles";

import { useAppStore, useUserStore } from "../../stores";
import FlagSite from "../FlagSite/FlagSite";

function CookieJarIcon() {
  return <Icon as={() => <Image h="20px" w="18px" src={logo} alt="Logo" />} />;
}

/*
 * This is the Hamburger Menu component that is used in the NavBar component.
 * It is also used elsewhere in the app.
 */
export default function NavBarHamburgerMenu({ commentCard }) {
  const {
    navigateTo,
    authModal: { onOpen },
    isMobile,
  } = useAppStore((state) => state);
  const { isLoggedIn, logout, user, login, register, profile, isAdmin } =
    useUserStore((state) => state);
  const lang = useAppStore((state) => state.language);
  const copy = C[lang].navBarHamburgerMenu;

  const navItems = [
    {
      index: "discover",
      icon: CookieJarIcon,
      name: copy.discover,
      method: navigateTo,
      visible: true,
    },
    {
      index: "categories",
      icon: DragHandleIcon,
      name: copy.categories,
      method: navigateTo,
      visible: true,
    },
    {
      index:
        "profile/@" + (profile?.username || "").toLowerCase() ||
        JSON.stringify(user.session.user).toLowerCase(),
      icon: AtSignIcon,
      name: copy.profile,
      method: navigateTo,
      visible: user?.session,
    },
    {
      index: "leaderboard",
      icon: StarIcon,
      name: copy.leaderboard,
      method: navigateTo,
      visible: true,
    },
    {
      index: "addsite",
      icon: AddIcon,
      name: copy.addSite,
      method: () => {
        isLoggedIn() ? navigateTo("addsite") : onOpen();
      },
      visible: true,
    },
    {
      index: "contact",
      icon: PhoneIcon,
      name: copy.contactUs,
      method: navigateTo,
      visible: true,
    },
    {
      index: "about",
      icon: QuestionOutlineIcon,
      name: copy.about,
      method: navigateTo,
      visible: true,
    },
    {
      index: copy.language,
      icon: CheckCircleIcon,
      name: copy.language,
      method: () => {
        useAppStore.getState().toggleLanguage();
      },
      visible: false,
    },
    {
      index: user?.session ? copy.logout : copy.login,
      icon: ViewOffIcon,
      name: user?.session ? copy.logout : copy.login,
      method: () => {
        user?.session ? logout() : login();
      },
      visible: isLoggedIn(),
    },
    {
      index: user?.session ? copy.logout : copy.login,
      icon: ViewOffIcon,
      name: user?.session ? copy.logout : copy.login,
      method: () => {
        user?.session ? logout() : login();
      },
      visible: !isLoggedIn(),
    },
    {
      index: "profile/me",
      icon: AtSignIcon,
      name: copy.register,
      method: () => {
        console.log(register);
        register();
      },
      visible: !user?.session,
    },
  ];

  return (
    <Menu overflow="hidden">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<HamburgerIcon />}
        variant="ghost"
      />
      <MenuList overflow="hidden">
        {commentCard && <MenuItem mt="-3">{commentCard}</MenuItem>}

        {isMobile && window.location.pathname.includes("discover") && (
          <MenuItem>
            <Box ml="2" mt="-3">
              <FlagSite />
            </Box>
          </MenuItem>
        )}
        {navItems.map((e) => (
          <>
            {e.visible ? (
              <MenuItem icon={<e.icon />} onClick={() => e.method(e.index)}>
                {e.name}
              </MenuItem>
            ) : null}
          </>
        ))}
      </MenuList>
    </Menu>
  );
}
