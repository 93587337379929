import { Flex, Text, Card, CardBody, CardHeader } from "@chakra-ui/react";

import { MainPage } from "../../components";
import { copy as C } from "../../configs/copy";

import { useAppStore } from "../../stores";

const PrivacyPolicyPage = () => {
  const language = useAppStore((state) => state.language);
  const privacyPolicyCopy = C[language].privacyPolicyPage;

  return (
    <MainPage
      top="-100px"
      title="Privacy Policy"
      justify="center"
      desktopWidth="85%"
      labelText={
        "This is the privacy policy, we regularly update it to ensure it stays up to standard. Please read it carefully."
      }
    >
      <CardBody>
        <Flex direction="column" align="center">
          <Card h="100%">
            <CardHeader>
              <Text fontSize="lg" fontWeight="bold" mb="4">
                Your Privacy Matters
              </Text>
              <Text fontSize="lg" mb="4">
                {privacyPolicyCopy.intro}
              </Text>
            </CardHeader>
            <CardBody>
              <Text fontSize="lg" fontWeight="bold">
                Data Collection
              </Text>
              <Text>{privacyPolicyCopy.dataCollection}</Text>

              <Text fontSize="lg" fontWeight="bold">
                Data Usage
              </Text>
              <Text>{privacyPolicyCopy.dataUsage}</Text>

              <Text fontSize="lg" fontWeight="bold">
                Cookies
              </Text>
              <Text>{privacyPolicyCopy.cookies}</Text>

              <Text fontSize="lg" fontWeight="bold">
                Authentication
              </Text>
              <Text>{privacyPolicyCopy.authentication}</Text>

              <Text fontSize="lg" fontWeight="bold">
                Image Storage
              </Text>
              <Text>{privacyPolicyCopy.imageStorage}</Text>

              <Text fontSize="lg" fontWeight="bold">
                Email Usage
              </Text>
              <Text>{privacyPolicyCopy.emailUsage}</Text>

              <Text fontSize="lg" fontWeight="bold">
                Third-Party Sharing
              </Text>
              <Text>{privacyPolicyCopy.thirdPartySharing}</Text>

              <Text fontSize="lg" fontWeight="bold">
                User Content
              </Text>
              <Text>{privacyPolicyCopy.userContent}</Text>
            </CardBody>
          </Card>
        </Flex>
      </CardBody>
    </MainPage>
  );
};

export default PrivacyPolicyPage;
