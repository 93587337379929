import React, { useEffect } from "react";

import { MainPage, AddSiteCard } from "../../components";
import { copy as C } from "../../configs/copy";

import { useAppStore, useUserStore } from "../../stores";

export default function AddSite() {
  const { language, navigateTo } = useAppStore((state) => state);
  const { isLoggedIn } = useUserStore((state) => state);
  const copy = C[language].addSitePage;

  useEffect(() => {
    !isLoggedIn() && navigateTo("discover");
  }, []);
  return (
    <MainPage
      top="-60"
      title={copy.addAWebsite}
      justify="right"
      desktopWidth="30%"
      labelText={'Here you can request to add a site. Who knows, it might be the next cookielisious hit!'}
    >
      <AddSiteCard />
    </MainPage>
  );
}
