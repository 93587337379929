import { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import logo from "../../assets/logo.svg";
import thumbs from "./assets/like.svg";
import { colours } from "../../configs/styles";
import { NavBarHamburgerMenu } from "../../components";

import { useSiteStore, useUserStore, useAppStore } from "../../stores";
import RateButton from "./RateButton";
import { Plus1Cookie } from "../../UI";
import { DeleteIcon } from "@chakra-ui/icons";
import FlagSite from "../FlagSite/FlagSite";

/*
 * This is the NavBar component that is used in the discover page.
 * It has all the nessecary actions needed to modify the current site.
 */
export default function NavBar({ commentCard }) {
  const { navigateTo, isMobile } = useAppStore((state) => state);
  const { setCurrent, isLoading, like, dislike, current } = useSiteStore(
    (state) => state
  );
  const barHeight = useBreakpointValue({ base: "9vh", md: "9vh" });
  const {
    authModal: { onOpen },
  } = useAppStore((state) => state);
  const { isLoggedIn, setAuthView } = useUserStore((state) => state);
  const [cookies, setCookies] = useState([]);
  const [count, setCount] = useState(0);

  const handleCookieClick = () => {
    const newCookie = { id: Date.now() };
    setCookies((prevCookies) => [...prevCookies, newCookie]);

    setTimeout(() => {
      setCookies((prevCookies) =>
        prevCookies.filter((cookie) => cookie.id !== newCookie.id)
      );
    }, 2000);
  };

  return (
    <Flex
      p="2"
      align="center"
      justify="space-between"
      w="100%"
      minH={barHeight}
      maxH="fit-content"
      color="black"
      borderTop={"solid 2px"}
      borderTopColor={"red.200"}
      bg={colours.primary}
    >
      <Flex
        flexDir="column"
        align="center"
        onClick={() => navigateTo("")}
        _hover={{ cursor: "pointer" }}
      >
        <Image src={logo} h="7" w="7" alt="Logo"/>
        <Heading fontSize="xs" color={colours.text.secondary}>
          CookieJar
        </Heading>
      </Flex>

      <Box textAlign="center">
        <ButtonGroup>
          <Box transform={"rotate(180deg)"} ml={!isMobile ? "40" : 0}>
            <RateButton
              onAction={() => {
                setCurrent();
                try {
                  dislike();
                } catch (e) {
                  console.log("Error DisLiking Site!");
                }
              }}
              color="red.500"
              image={thumbs}
            />
          </Box>
          <Box position="relative">
            <RateButton
              onAction={() => {
                setCurrent();
                handleCookieClick();
                try {
                  like();
                } catch (e) {
                  console.log("Error Liking Site!");
                }

                setCount((prev) => prev + 1);
                if (!isLoggedIn() & (count % 5 === 0) & (count > 0)) {
                  setAuthView(true);
                  onOpen();
                }
              }}
              color="green.400"
              image={thumbs}
            />
            <Plus1Cookie cookies={cookies} />
          </Box>
          <a
            style={{ fontSize: "0px" }}
            href={window.location.href + "discover/" + current.id}
          >
            current site
          </a>
        </ButtonGroup>
      </Box>
      <Flex>
        <Flex gap="4" align="center" justify="center">
          {!isMobile && commentCard}
          {!isMobile && <FlagSite />}
          <NavBarHamburgerMenu commentCard={isMobile && commentCard} />
        </Flex>
      </Flex>
    </Flex>
  );
}
